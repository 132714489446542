.swiper
	.swiper-pagination
		@apply bottom-5 xl:bottom-10
		.swiper-pagination-bullet
			@apply opacity-100 rounded-full w-3 h-3 bg-transparent border-2 border-primary-500 transition-all duration-200 ease-linear mx-[calc((5/2)/1920*100rem)]
			&.swiper-pagination-bullet-active
				@apply bg-primary-500 border-primary-500
		&.swiper-pagination-white
			.swiper-pagination-bullet
				@apply border-white
				&.swiper-pagination-bullet-active
					@apply bg-primary-500 border-primary-500
	.swiper-button > *
		@apply opacity-100 flex-center text-xl text-white transition-all duration-200 ease-linear
		&.swiper-button-disabled
			@apply opacity-40 pointer-events-none
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	.button-absolute
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-10
		.button-prev
			@apply left-10 xl:left-15
		.button-next
			@apply right-10 xl:right-15

.swiper-relative
	@apply relative z-2 pb-10 xl:pb-0
	.swiper-pagination
		@apply bottom-0 xl:hidden
		.swiper-pagination-bullet
			@apply opacity-100 rounded-full w-3 h-3 bg-transparent border-2 border-primary-500 transition-all duration-200 ease-linear mx-[calc((5/2)/1920*100rem)]
			&.swiper-pagination-bullet-active
				@apply bg-primary-500 border-primary-500
	.swiper-button > *
		@apply w-10 h-10 rounded-full bg-transparent opacity-100 flex-center text-4xl leading-normal text-primary-500 transition-all duration-200 ease-linear xl:hover:bg-transparent xl:hover:text-primary-500
		&.swiper-button-disabled
			@apply opacity-40 pointer-events-none
		&.swiper-button-lock
			@apply opacity-0 pointer-events-none
	.button-absolute
		> *
			@apply absolute top-1/2 -translate-y-1/2 z-10
		.button-prev
			@apply -left-10 xl:-left-15
		.button-next
			@apply -right-10 xl:-right-15
	.swiper-button
		@apply hidden xl:block
		// &.button-white
		// 	> *
		// 		@apply bg-white xl:hover:bg-primary-500

.equal-swiper
	.swiper-slide
		@apply h-auto
		> *
			@apply h-full

.equal-height
	.col
		> *
			@apply h-full
