.blog-item
	.img-scale
		@apply rounded-3 pt-[calc(218/390*100%)]
	.blog-caption
		@apply mt-4
	.blog-date
		@apply body-4-text
		@apply font-medium text-gray-66 leading-normal
	.blog-title
		@apply body-2-text
		@apply mt-4 font-medium leading-1.44 text-gray-33 uppercase line-clamp-2 hover:text-primary-500
	.blog-brief
		@apply body-3-text
		@apply mt-5 font-normal text-gray-66 leading-normal line-clamp-3
	@screen md
		&.is-hor
			@apply flex
			.blog-image
				@apply w-[calc(288/1920*100rem)]
			.blog-caption
				@apply flex-1 pl-5 mt-0 pt-3
			.blog-title
				@apply mt-3
			&.is-small
				@apply pb-0
				.blog-image
					@apply w-[calc(178/1920*100rem)]
				.img-scale
					@apply pt-[calc(100/178*100%)]
				.blog-caption
					@apply pl-7.5 pt-0
				.blog-title
					@apply text-lg line-clamp-3 mt-3 leading-1.44
	@screen xl
		@apply min-h-[calc(352/1920*100rem)] pb-5
		&.is-hor
			@apply min-h-0
		&:hover
			.blog-image
				img
					@apply scale-110

.blog-list-section
	@apply relative z-1
	@screen xl
		&::before
			content: ''
			@apply bg-[url('../img/bg-blog-1.png')] bg-contain bg-no-repeat bg-center
			@apply absolute top-25 left-0 -z-1 pointer-events-none w-[calc(241/1920*100rem)] h-[calc(629/1920*100rem)]
		&::after
			content: ''
			@apply bg-[url('../img/bg-blog-2.png')] bg-contain bg-no-repeat bg-center
			@apply absolute top-[calc(320/1920*100rem)] right-0 -z-1 pointer-events-none w-[calc(178/1920*100rem)] h-[calc(520/1920*100rem)]
		.container
			@apply relative z-2
