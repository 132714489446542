@layer components
	.container
		@screen xs
			@apply max-w-[calc(500/1920*100rem)]
		@screen sm
			@apply max-w-[calc(576/1920*100rem)]
		@screen md
			@apply max-w-[calc(768/1920*100rem)]
		@screen lg
			@apply max-w-[calc(1024/1920*100rem)]
		@screen xl
			@apply max-w-[calc(1260/1920*100rem)]
		@screen 2xl
			@apply max-w-[calc(1260/1920*100rem)]

	.full-content
		@apply max-w-none text-gray-33 xl:text-lg
