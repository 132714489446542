@layer utilities
	.container-fuild
		@apply px-3.75

	.section-small
		@apply py-10

	.section
		@apply py-15

	.section-large
		@apply py-15 xl:py-20

	.row
		@apply flex flex-wrap -mx-3.75
		> *
			@apply px-3.75

	.no-gutters
		@apply mx-0
		> *
			@apply px-0

	.swiper-relative
		@apply relative

	.swiper-webgl
		@apply w-full h-full

	.writing-mode-tb-rl
		writing-mode: vertical-rl
		text-orientation: mixed

	.media-scale
		@apply relative block h-0 overflow-hidden
		img, iframe, video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out

	.img-scale
		@apply relative block h-0 overflow-hidden
		img
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.iframe-scale
		@apply relative block h-0 overflow-hidden
		iframe
			@apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out

	.video-scale
		@apply relative block h-0 overflow-hidden
		video
			@apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover

	.bg-bright-grey
		@apply backdrop-blur-25

	.absolute-center
		@apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2

	.filter-white
		@apply brightness-0 invert

	.img-zoom-in
		&:hover
			img
				@apply scale-110

	.hover-underline
		@apply relative
		background: linear-gradient(0deg, theme('colors.current'), theme('colors.current')) no-repeat right bottom / 0 theme('spacing[px]')
		transition: background-size 350ms
		&:hover
			background-size: 100% theme('spacing[px]')
			background-position-x: left

	.hover-underline-active
		background-size: 100% theme('spacing[px]')
		background-position-x: left

	.mobile-only
		@apply lg:hidden

	.desktop-only
		@apply hidden lg:block

	.lozad-bg
		@apply bg-no-repeat bg-center bg-cover

	.custom-scroll
		@apply pr-7.5 overflow-y-auto
		&::-webkit-scrollbar
			@apply w-0.5
		&::-webkit-scrollbar-track
			@apply bg-gray-50
		&::-webkit-scrollbar-thumb
			@apply bg-primary-500
		&::-webkit-scrollbar-thumb:hover
			@apply bg-primary-700
