.recruitment-detail-wrap
	box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.1)
	@apply bg-white rounded-5 p-5 lg:pb-10 xl:pb-12.5
	.recruitment-detail-title
		@apply pb-5 border-b border-b-gray-50 mb-5 lg:px-5 xl:text-4xl
	.recruitment-block-content
		@apply space-y-5 lg:px-5 lg:space-y-6
	.global-title
		@apply normal-case
	.block-content
		@apply font-medium text-lg text-gray-33
		h2
			@apply font-bold mb-5 xl:text-32
		h3
			@apply font-medium mb-5 xl:text-2xl
		* + h2
			@apply mt-5 xl:mt-5.5
		* + h3
			@apply mt-5 xl:mt-6.5
	.button
		@apply lg:px-5 xl:pl-15 xl:mt-15

.recruitment-other-item
	@apply bg-gray-f5 p-5 rounded-1
	.title
		@apply body-2-text
		@apply font-bold text-gray-33 hover:text-primary-500 leading-1.44 pb-3.75 mb-3.75 relative
		&::before
			content: ''
			@apply absolute left-0 top-full w-9 h-0.5 bg-gray-33
	.caption
		@apply body-2-text
		@apply font-medium text-gray-33 leading-1.44
		.end-date
			@apply text-secondary-500

.recruitment-detail-other
	.other-title
		@apply heading-5-title
		@apply font-normal text-primary-500 leading-1.33 mb-7.5 lg:mb-11
	.recruitment-list
		@apply space-y-7.5


.recruitment-detail
