+formGroup()
	@apply relative w-full bg-white border border-solid border-primary-500 h-10 pl-6.5 pr-12.5 py-1 text-sm text-desc font-normal rounded-5
	@apply focus:border-secondary-500 focus:ring-secondary-500
	@apply placeholder-shown:bg-white
	@apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black-1000
	@apply read-only:bg-gray-e5 read-only:border-gray-cc read-only:text-black-1000

select
	background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%230B3F7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>")
	@apply relative w-full bg-white border border-solid border-primary-500 h-10 pl-6.5 pr-12.5 py-1 text-sm text-desc font-normal rounded-5
	@apply focus:border-secondary-500 focus:ring-secondary-500
	@apply placeholder-shown:bg-white
	@apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black-1000

textarea
	@apply h-17.5 py-2.5

input[type='file']
	@apply hidden
	~ label
		@apply relative w-max bg-gray-e5 border-none h-12 px-6 py-3 text-base text-primary-700 font-normal flex-center space-x-3 cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-cc

.btn-submit,
input[type="submit"],
button[type="submit"]
	@apply block h-9 px-6.5 space-x-2 text-base font-medium text-secondary-500 text-center border-primary-500 rounded-0.75 bg-primary-500 transition-all duration-200 ease-linear cursor-pointer xl:hover:bg-primary-700

.form-group
	@apply mb-6 relative space-y-2
	label
		@apply w-full block text-lg font-bold leading-[calc(22/18)] text-gray-33
	> span, > div > span
		@apply text-xs text-red-900 italic block mt-0.5 font-primary font-normal
		@apply before:hidden

.input-group
	@apply relative
	.input-group-icon
		@apply absolute top-1/2 -translate-y-1/2 right-4.5 z-1 text-xl text-primary-700 leading-none cursor-pointer m-0
	.toggle-password
		&.active
			@apply before:content-['\f06e']

// button submit
.frm-btnwrap
	label.label
		@apply hidden
	.frm-btn-reset
		@apply hidden

// captcha
.frm-captcha
	@apply flex flex-wrap -mt-5 lg:flex-row-reverse lg:justify-end
	> div
		@apply mt-5
	label
		@apply hidden
	.frm-captcha-input
		@apply w-full lg:w-auto
		input
			@apply w-full xl:max-w-[calc(260/1920*100rem)]
	.RadCaptcha
		@apply flex flex-col-reverse
		@apply w-max
		> div
			@apply w-full
			> div
				@apply flex items-center
		> span
			@apply text-[12px] text-secondary-700 italic block mt-0.5 font-primary font-normal
	.rcRefreshImage
		font-size: 0
		@apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)]
