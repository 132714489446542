.blog-detail-section
	.block-content
		@apply xl:pr-7.5
	.date
		@apply body-4-text
		@apply font-medium text-gray-66 leading-normal
	.blog-detail-title
		@apply text-2xl leading-1.2 font-bold text-gray-33 mt-5 pb-5 mb-5 border-b border-b-gray-100 md:text-3xl lg:text-4xl xl:text-40
	.blog-list
		@apply space-y-7.5 mt-5
	.social-list
		@apply mt-7.5
		@screen xl
			@apply absolute right-full top-0 mt-0 flex-col mr-7.5

.other-title
	@apply heading-5-title
	@apply font-normal text-primary-500 leading-1.33
