// CMS admin panel
.cmsadminpanel
	.ddviewmode
		@apply h-[24px] text-[12px] py-1 px-2 pr-5 leading-none

// Core - Pagination
.modulepager
	@apply mt-7.5 xl:mt-10
	.pagination
		@apply flex-center flex-wrap gap-5
	a, span
		@apply relative z-1 w-10 h-10 rounded-2 flex-center text-base text-primary-500 font-normal transition-all duration-300 ease-linear border border-primary-500 xl:w-12.5 xl:h-12.5 xl:text-2xl
	li
		&.active, &:hover
			a, span
				@apply text-white bg-primary-500

// Core - Searchbox
.searchbox
	@apply relative
	input
		@apply w-full h-8 border border-gray-100 rounded-full pl-3 pr-12 text-sm text-gray-400 bg-white
		@apply placeholder-shown:bg-white
		@apply focus:ring-secondary-300 focus:outline-none focus:shadow-none focus:border-secondary-300
	button
		@apply absolute top-1/2 -translate-y-1/2 right-2 z-1 w-10 h-10 flex-center text-base text-gray-400

.suggestsearch
	display: none !important

// Core - Search Pages
.searchresults
	.search-page
		@apply py-15 xl:py-20
	.page-header
		h1
			@apply  uppercase
			@apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px
	.searchresultsummary
		@apply text-[11px] xl:text-[13px] mb-3
	.searchcontrols
		.form-inline
			@apply relative
		.form-group
			@apply hidden first:block last:block last:absolute last:w-12.5 last:h-full last:top-0 last:right-0 last:z-1 last:text-[0] last:mb-0 last:flex-center
			@apply last:before:absolute last:before:w-full last:before:h-full last:before:font-awesome last:before:content-['\f002'] last:before:text-blue-500 last:before:text-2xl last:before:flex-center last:before:pointer-events-none
			&:first-child
				input
					@apply pr-14
			&:last-child
				input
					@apply z-2 w-12.5 h-full relative top-0 right-0 opacity-0
	.searchresults
		> .modulepager
			@apply hidden
			&:last-child
				@apply block
	.searchresultlist
		@apply px-1 xl:px-5
	.searchresult
		@apply mt-10
		h3
			a
				@apply text-xl hover:underline hover:text-white
		.searchresultdesc
			@apply mt-1 text-base
		hr
			@apply hidden

// login
.login-box
	@apply py-25 px-10 xl:py-30
	.card
		@apply bg-gray-f5 mx-auto p-5
		@screen xl
			@apply max-w-[calc(750/1920*100rem)] px-[calc(95/1920*100rem)] pt-7 pb-15
	.input-group
		@apply mb-5
		input
			@apply border-white bg-white w-full rounded-1.25 h-12.5 text-gray-33 text-base px-5 placeholder:text-gray-99
	.input-group-append
		@apply hidden
	.btn.btn-primary.btn-block, .btn.btn-default.createuserbutton
		@apply cursor-pointer bg-black-1000 border-black-1000 transition-all h-11 text-center text-white font-normal uppercase text-sm px-10 rounded-full xl:min-w-[calc(183/1920*100rem)] xl:hover:bg-primary-700 xl:hover:border-primary-700
	.social-login
		@apply hidden
	.row.items-center
		@apply justify-between
	a
		@apply text-base xl:text-sm

.wrap-register
	> div > div
		@apply shadow rounded-5 overflow-hidden bg-white mx-auto p-5
		@screen xl
			@apply max-w-[calc(600/1920*100rem)] p-7
	.settingrow
		.RadInput
			.riTextBox
				@apply w-full bg-gray-66 border border-solid border-gray-ee rounded-1.25 h-12.5 px-5 text-base text-gray-22 leading-normal xl:text-sm

.login-logo, .pagetitle
	@apply text-2xl font-light text-secondary-700 uppercase leading-tight text-center mb-8

.login-box-msg
	@apply text-base leading-[1.38] text-secondary-700 font-normal mb-5

.icheck-primary
	@apply form-group text-base gap-3 flex items-center
	label
		@apply mt-0

.login-download-section
	.login-box
		@apply p-0 xl:p-0
		.btn-download-submit
			@apply w-full
	.module-description
		@apply text-base text-secondary-700 font-normal

.login-card-body
	.flex
		a
			@apply hover:text-primary-50 mr-5
