.gmaps-dealer-wrap
	filter: drop-shadow(0px -4px 5px rgba(126, 157, 93, 0.25)) drop-shadow(0px 4px 5px rgba(126, 157, 93, 0.25))
	@apply bg-white rounded-4
	.dealer-maps
		@apply h-full min-h-[calc(300/1920*100rem)]
		iframe
			@apply w-full h-full min-w-full
	.dealer-filter
		@apply p-5 xl:pt-12 xl:pl-7.5 xl:pr-11 xl:pb-4
	.wrap-form
		@apply space-y-3
	.form-group
		@apply m-0
		select
			@apply bg-green-100 h-12.5 border-none px-6 text-lg font-normal text-gray-54 rounded-2
	.frm-btnwrap
		.btn-submit, input[type='submit'], button[type='submit']
			@apply min-w-[calc(182/1920*100rem)] text-white h-11 rounded-0.75 text-xl hover:text-white
	#list
		@apply mt-9 max-h-[calc(313/1920*100rem)] custom-scroll
	li
		@apply py-5 text-base leading-tight text-desc flex gap-2 font-secondary border-b border-b-desc/10 cursor-pointer transition-all duration-200 ease-linear xl:hover:bg-gray-99/10
		i, em
			@apply text-base leading-none text-desc mt-1
		&.active
			@apply bg-gray-99/10

.dealer-zone-wrap
	@apply p-7.5 rounded-3 border-2 border-gray-50
	.zone-title
		@apply heading-3-title
		@apply font-bold leading-tight text-gray-33
	.zone-list
		@apply space-y-5 mt-5 xl:px-3.75
		a
			&.active
				@apply bg-primary-500 text-white
				span
					@apply text-white
		li
			&.active
				a
					@apply bg-primary-500 text-white
					span
						@apply text-white

.dealer-item
	@apply relative
	&::before
		content: ''
		@apply absolute-center w-full h-full border border-gray-50 rounded-5 pointer-events-none
	.img-scale
		@apply pt-[calc(250/443*100%)] rounded-5
	.dealer-caption
		@apply mt-3 px-5 pb-7.5 xl:pb-10
	.dealer-title
		@apply text-xl font-medium leading-1.4 text-gray-33 hover:text-primary-500
	.button
		@apply mt-3 -mx-5
		.btn
			@apply w-full
	.dealer-info
		@apply mt-5 pt-3 border-t border-t-gray-50
	ul
		@apply space-y-5
	li
		@apply flex text-lg font-medium leading-1.44 text-gray-66
		i,em
			@apply text-base leading-none text-black-1000 w-6.5 mt-1

.load-item-init
	.load-item
		@apply hidden

.dealer-section
