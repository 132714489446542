.heading-1-title
	@apply text-5xl leading-normal xl:text-6xl

.heading-2-title
	@apply text-4xl leading-normal xl:text-5xl

.heading-3-title
	@apply text-3xl leading-normal xl:text-4xl

.heading-4-title
	@apply text-2xl leading-normal xl:text-32

.heading-5-title
	@apply text-xl leading-normal xl:text-2xl

.body-1-text
	@apply text-xl leading-snug

.body-2-text
	@apply text-lg leading-snug

.body-3-text
	@apply text-base leading-snug

.body-4-text
	@apply text-sm leading-snug

.body-5-text
	@apply text-xs leading-snug

.global-title
	@apply text-2xl font-medium uppercase leading-tight md:text-3xl lg:text-4xl xl:text-5xl
	&.title-small
		@apply lg:text-3xl xl:text-32

.global-desc
	@apply xl:text-lg
