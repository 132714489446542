.menu-detail-section
	.product-detail-top
		.product-image
			@apply bg-gray-ef rounded-5 overflow-hidden
		.img-scale
			@apply pt-[calc(711/600*100%)]
			img
				@apply absolute-center max-w-[75%] max-h-[75%] object-contain
		.swiper-relative
			@apply mt-10 px-15 pb-0
		.swiper-thumbs
			.product-image
				@apply rounded-3
		.swiper-button
			@apply block
			> *
				@apply absolute top-1/2 -translate-y-1/2
			.button-prev
				@apply left-2
			.button-next
				@apply right-2
	.product-title
		@apply text-2xl font-bold leading-tight text-primary-500 xl:text-32
	.product-price
		@apply mt-5 text-2xl font-bold leading-tight text-green-500 xl:text-32
	.product-size
		@apply mt-7.5 flex items-center gap-5 text-lg font-medium leading-1.44 text-gray-66
	.size-list
		@apply flex items-center gap-7.5
		a
			@apply body-2-text
			@apply flex-center w-12.5 h-12.5 border border-primary-500 text-primary-500 bg-white rounded-1 hover:bg-secondary-500 hover:border-secondary-500
			&.active
				@apply bg-secondary-500 border-secondary-500
	.product-topping
		@apply mt-12.5 text-lg font-medium leading-1.44 text-gray-66
	.topping-list
		@apply mt-7.5 max-h-[calc(315/1920*100rem)] custom-scroll
		li
			@apply body-2-text
			@apply flex items-center justify-between gap-2.5 text-gray-66 leading-tight py-2.75 border-b border-b-gray-100
		.topping-price
			@apply text-green-500
	.product-button
		@apply mt-7.5 xl:mt-15
	.product-detail-bot
		> *
			@apply mt-10 pt-10 border-t border-t-gray-100
	.product-other-title
		@apply heading-5-title
		@apply font-bold leading-1.33 text-gray-33 mb-5
	.full-content
		@apply body-2-text
		@apply font-medium leading-1.44 text-gray-66
	.product-relative
		.product-other-title
			@apply text-primary-500
