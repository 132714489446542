.vision-mission-item
	@apply text-center max-w-[calc(280/1920*100rem)] mx-auto
	.icon
		@apply w-25 h-25 mx-auto flex-center
		img
			@apply w-full h-full object-contain
	.caption
		@apply mt-7.5
	.title
		@apply heading-4-title
		@apply font-bold text-primary-500 leading-tight
	.desc
		@apply body-2-text
		@apply mt-5 font-medium leading-1.44

.core-value-item
	@apply relative
	&::before
		content: ''
		background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%)
		@apply absolute-center w-full h-full z-1 pointer-events-none
	.img-scale
		@apply pt-[calc(302/310*100%)]
	.title
		@apply body-3-text
		@apply absolute left-0 w-full bottom-0 z-1 px-3.75 py-5 text-center text-white font-normal leading-normal z-2
	@screen xl
		@apply h-[calc(304/1920*100rem)]
		.image
			@apply h-full pt-0
		&:hover
			.image
				img
					@apply scale-110
.about-2
	@apply xl:pb-0
	.global-desc
		@apply mt-7.5 font-medium leading-1.44
	.vision-mission-wrap
		@apply mt-10 xl:mt-20

.about-3
	background-size: inherit
	@apply bg-bottom xl:bg-cover xl:pt-20
	.global-desc
		@apply body-2-text
		@apply font-medium leading-1.44
	.block-content
		@apply py-15 xl:pt-27 xl:pb-37

.about-4
	.core-value-item
		@screen lg
			&:first-child
				@apply col-span-2 h-[calc(304/1920*100rem)]
				.image
					@apply h-full pt-0
		@screen xl
			&:first-child
				@apply row-span-2 col-span-1
				@apply h-full
			&:nth-child(3)
				@apply col-span-2
			&:last-child
				@apply col-span-2

.about-5
	.blog-list
		@apply mt-10 xl:mt-15
	.blog-item
		&.is-hor
			& +.blog-item
				@apply mt-7.5
