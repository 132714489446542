header
	@apply bg-white fixed top-0 left-0 w-full right-0 z-1000
	&::before
		content: ''
		@apply absolute bottom-0 left-0 w-full h-px bg-[#EAEBED] pointer-events-none
	.header-wrap
		@apply flex justify-between items-center
	.logo
		@apply h-25 py-2.5 xl:h-30 xl:py-5
		.ModuleContent
			@apply flex h-full
			a
				@apply flex-center
		img
			@apply w-full h-full object-contain
	.header-top
		@apply py-3.5 justify-end items-center gap-6 hidden xl:flex
	.header-bot
		@apply flex items-center gap-5 xl:gap-0
		.navbar-nav
			@apply hidden xl:block
		.button-search
			@apply text-lg w-10 h-10 flex-center xl:hidden
		@screen xl
			@apply relative
			&::before
				content: ''
				@apply absolute top-0 left-0 w-full h-px bg-[#EAEBED] pointer-events-none
	.language
		@apply flex items-center gap-3
		em, i
			@apply text-base text-primary-500
		ul
			@apply flex-center gap-2
			a
				@apply text-gray-500 font-normal uppercase hover:text-primary-500
		li
			@apply relative
			& + li
				&:before
					content: ''
					@apply h-3 w-0.5 bg-gray-100 absolute top-1/2 -translate-y-1/2 -left-1
			&.active
				a
					@apply text-light-green font-semibold
	.header-line-ver
		@apply w-px h-4 bg-gray-100
	.main-menu
		@apply flex justify-end items-center gap-7.5 xl:gap-[35px] 2xl:gap-[calc(35/1920*100rem)]
		a
			@apply text-gray-33 font-bold uppercase text-center block py-4.5 hover-underline hover:text-light-green
		li
			&.active
				a
					@apply text-light-green hover-underline-active
	.searchbox
		@apply w-[calc(192/1920*100rem)]
	.btn-fanpage
		@apply bg-transparent hover:bg-primary-50 -mr-2.5

.btn-fanpage
	@apply px-2.5 py-1 rounded-1.25 bg-white flex-center text-gray-400 font-semibold text-base leading-normal bg-primary-50
	span, em, i
		@apply text-light-green
	em
		@apply mr-2

.mobile-wrap
	@apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-32 pl-3.75 pb-3.75 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden
	.navbar-nav-list
		@apply pr-3.75 mb-10
	.main-menu
		@apply flex flex-col gap-2
		a
			@apply text-gray-33 font-bold uppercase block py-1 hover:text-primary-500
		li
			&.active
				a
					@apply text-primary-500 pl-3.75 border-l-2 border-l-primary-500
	.btn-fanpage
		@apply mr-5 w-max px-5 py-1.5
	&.open
		@apply left-0 opacity-100 pointer-events-auto

.search-wrap
	@apply fixed top-1/4 w-screen px-5 z-998 opacity-0 scale-80 transition-all duration-500 ease-in-out pointer-events-none xl:hidden
	.searchbox
		input
			@apply h-10 pl-5 text-lg
		button
			@apply text-xl
	&.open
		@apply scale-100 opacity-100 pointer-events-auto
