@charset "UTF-8";
/**
 * This injects Tailwind's base styles and any base styles registered-500 by
 * plugins.
 */
@tailwind base {}/**
 * This injects Tailwind's component classes and any component classes
 * registered-500 by plugins.
 */
@tailwind components {}/**
 * This injects Tailwind's utility classes and any utility classes registered-500
 * by plugins.
 */
@tailwind utilities {}/**
 * Use this directive to control where Tailwind injects the hover, focus,
 * responsive, dark mode, and other variants of each class.
 *
 * If omitted, Tailwind will append these classes to the very end of
 * your stylesheet by default.
 */
@tailwind variants {}.edit-link i:before {
  content: '✏️'; }

.social-list {
  @apply flex items-center gap-3 {} }
  .social-list a {
    @apply flex-center w-12.5 h-12.5 bg-primary-500 text-white text-2xl rounded-2.5 hover:bg-primary-700 {} }

.nav-fixed {
  @apply fixed z-50 bottom-24 right-3 xl:bottom-14 xl:right-12 {} }
  .nav-fixed ul {
    @apply flex flex-col gap-2.5 {} }
  .nav-fixed li {
    @apply w-15 h-15 rounded-full bg-white overflow-hidden shadow {} }
    .nav-fixed li a {
      @apply flex-center w-full h-full text-2xl text-primary-500 {} }
    .nav-fixed li.back-to-top {
      @apply opacity-0 pointer-events-none {} }
      .nav-fixed li.back-to-top.active {
        @apply opacity-100 pointer-events-auto {} }

@layer base {
  html, body {
    font-size: clamp(14px, 16px, 36px); }
  @screen md {
    html, body {
      font-size: 15px; } }
  @screen xl {
    html, body {
      font-size: 1.25vw; } }
  @screen 2xl {
    html, body {
      font-size: 1vw; } }
  body {
    @apply font-primary overflow-y-overlay text-main {} }
  main {
    @apply pt-25 xl:pt-30 {} }
  h1 {
    @apply text-3xl font-bold {} }
  h2 {
    @apply text-2xl font-bold {} }
  h3 {
    @apply text-xl font-bold {} }
  h4 {
    @apply text-lg font-bold {} }
  h5 {
    @apply text-base font-bold {} }
  h6 {
    @apply text-sm font-bold {} }
  a {
    @apply transition-all ease-linear duration-200 xl:hover:text-current {} }
  img {
    @apply max-w-full inline {} }
  main, div {
    @apply xl:text-base {} } }

@layer components {
  @screen xs {
    .container {
      @apply max-w-[calc(500/1920*100rem)] {} } }
  @screen sm {
    .container {
      @apply max-w-[calc(576/1920*100rem)] {} } }
  @screen md {
    .container {
      @apply max-w-[calc(768/1920*100rem)] {} } }
  @screen lg {
    .container {
      @apply max-w-[calc(1024/1920*100rem)] {} } }
  @screen xl {
    .container {
      @apply max-w-[calc(1260/1920*100rem)] {} } }
  @screen 2xl {
    .container {
      @apply max-w-[calc(1260/1920*100rem)] {} } }
  .full-content {
    @apply max-w-none text-gray-33 xl:text-lg {} } }

@layer utilities {
  .container-fuild {
    @apply px-3.75 {} }
  .section-small {
    @apply py-10 {} }
  .section {
    @apply py-15 {} }
  .section-large {
    @apply py-15 xl:py-20 {} }
  .row {
    @apply flex flex-wrap -mx-3.75 {} }
    .row > * {
      @apply px-3.75 {} }
  .no-gutters {
    @apply mx-0 {} }
    .no-gutters > * {
      @apply px-0 {} }
  .swiper-relative {
    @apply relative {} }
  .swiper-webgl {
    @apply w-full h-full {} }
  .writing-mode-tb-rl {
    writing-mode: vertical-rl;
    text-orientation: mixed; }
  .media-scale {
    @apply relative block h-0 overflow-hidden {} }
    .media-scale img, .media-scale iframe, .media-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .img-scale {
    @apply relative block h-0 overflow-hidden {} }
    .img-scale img {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .iframe-scale {
    @apply relative block h-0 overflow-hidden {} }
    .iframe-scale iframe {
      @apply absolute min-w-full w-0 h-full top-0 left-0 transition-all duration-300 ease-in-out {} }
  .video-scale {
    @apply relative block h-0 overflow-hidden {} }
    .video-scale video {
      @apply absolute w-full h-full top-0 left-0 transition-all duration-300 ease-in-out object-cover {} }
  .bg-bright-grey {
    @apply backdrop-blur-25 {} }
  .absolute-center {
    @apply absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 {} }
  .filter-white {
    @apply brightness-0 invert {} }
  .img-zoom-in:hover img {
    @apply scale-110 {} }
  .hover-underline {
    @apply relative {}    background: linear-gradient(0deg, theme("colors.current"), theme("colors.current")) no-repeat right bottom/0 theme("spacing[px]");
    transition: background-size 350ms; }
    .hover-underline:hover {
      background-size: 100% theme("spacing[px]");
      background-position-x: left; }
  .hover-underline-active {
    background-size: 100% theme("spacing[px]");
    background-position-x: left; }
  .mobile-only {
    @apply lg:hidden {} }
  .desktop-only {
    @apply hidden lg:block {} }
  .lozad-bg {
    @apply bg-no-repeat bg-center bg-cover {} }
  .custom-scroll {
    @apply pr-7.5 overflow-y-auto {} }
    .custom-scroll::-webkit-scrollbar {
      @apply w-0.5 {} }
    .custom-scroll::-webkit-scrollbar-track {
      @apply bg-gray-50 {} }
    .custom-scroll::-webkit-scrollbar-thumb {
      @apply bg-primary-500 {} }
    .custom-scroll::-webkit-scrollbar-thumb:hover {
      @apply bg-primary-700 {} } }

.fa {
  font-family: var(--fa-style-family, "Font Awesome 6 Pro");
  font-weight: var(--fa-style, 900); }

.fa, .fas, .fa-solid, .far, .fa-regular, .fal, .fa-light, .fat, .fa-thin, .fad, .fa-duotone, .fab, .fa-brands {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: var(--fa-display, inline-block);
  font-style: normal;
  font-variant: normal;
  line-height: 1;
  text-rendering: auto; }

::root, ::host {
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"; }

@font-face {
  font-family: 'Font Awesome 6 Brands';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-brands-400.woff2") format("woff2"), url("../fonts/fa-brands-400.ttf") format("truetype"); }

.fab,
.fa-brands {
  font-family: 'Font Awesome 6 Brands';
  font-weight: 400; }

::root, ::host {
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src: url("../fonts/fa-light-300.woff2") format("woff2"), url("../fonts/fa-light-300.ttf") format("truetype"); }

.fal,
.fa-light {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 300; }

::root, ::host {
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("../fonts/fa-regular-400.woff2") format("woff2"), url("../fonts/fa-regular-400.ttf") format("truetype"); }

.far,
.fa-regular {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 400; }

::root, ::host {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("../fonts/fa-solid-900.woff2") format("woff2"), url("../fonts/fa-solid-900.ttf") format("truetype"); }

.fas,
.fa-solid {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 900; }

::root, ::host {
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Pro"; }

@font-face {
  font-family: 'Font Awesome 6 Pro';
  font-style: normal;
  font-weight: 100;
  font-display: block;
  src: url("../fonts/fa-thin-100.woff2") format("woff2"), url("../fonts/fa-thin-100.ttf") format("truetype"); }

.fat,
.fa-thin {
  font-family: 'Font Awesome 6 Pro';
  font-weight: 100; }

.heading-1-title {
  @apply text-5xl leading-normal xl:text-6xl {} }

.heading-2-title {
  @apply text-4xl leading-normal xl:text-5xl {} }

.heading-3-title {
  @apply text-3xl leading-normal xl:text-4xl {} }

.heading-4-title {
  @apply text-2xl leading-normal xl:text-32 {} }

.heading-5-title {
  @apply text-xl leading-normal xl:text-2xl {} }

.body-1-text {
  @apply text-xl leading-snug {} }

.body-2-text {
  @apply text-lg leading-snug {} }

.body-3-text {
  @apply text-base leading-snug {} }

.body-4-text {
  @apply text-sm leading-snug {} }

.body-5-text {
  @apply text-xs leading-snug {} }

.global-title {
  @apply text-2xl font-medium uppercase leading-tight md:text-3xl lg:text-4xl xl:text-5xl {} }
  .global-title.title-small {
    @apply lg:text-3xl xl:text-32 {} }

.global-desc {
  @apply xl:text-lg {} }

.button {
  @apply flex flex-wrap gap-5 {} }

.nav-button-list {
  @apply flex flex-wrap gap-5 xl:gap-7.5 {} }
  .nav-button-list li.active a {
    transform: translate(6px, -6px);
    @apply bg-secondary-500 text-primary-500 border-secondary-500 {} }
    .nav-button-list li.active a::after {
      transform: translate(-6px, 6px);
      @apply opacity-100 {} }
    .nav-button-list li.active a.btn-hover-bg-lined {
      @apply after:border-secondary-500 {} }

.link {
  @apply flex-center space-x-2.5 font-bold text-base text-black-1000 xl:text-sm xl:hover:text-primary-700 {} }
  .link i, .link em {
    @apply text-primary-700 transition-all duration-200 ease-linear xl:text-xl xl:hover:text-secondary-700 {} }
  .link.link-primary {
    @apply text-primary-700 xl:hover:text-secondary-700 {} }
  .link.link-gray-33 {
    @apply text-gray-33 xl:hover:text-secondary-700 {} }
  .link.link-white {
    @apply text-white xl:hover:text-secondary-700 {} }
    .link.link-white i, .link.link-white em {
      @apply text-white xl:hover:text-secondary-700 {} }

.btn {
  @apply relative flex-center h-11 px-5.5 gap-2 rounded-0.75 border-2 border-solid border-current text-lg font-medium leading-normal min-w-[calc(180/1920*100rem)] max-w-full z-1 {} }

.btn-small {
  @apply h-9 min-w-0 border {} }

.btn-big {
  @apply h-15 px-7.5 {} }

.btn-solid {
  @apply text-white bg-primary-500 border-primary-500 {} }
  .btn-solid.btn-primary {
    @apply bg-primary-500 text-secondary-500 border-primary-500 hover:text-primary-500 hover:bg-white {} }
    .btn-solid.btn-primary.btn-hover-bg-lined {
      @apply after:border-primary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-secondary-500 hover:after:border-secondary-500 {} }
  .btn-solid.btn-secondary {
    @apply bg-secondary-500 text-primary-500 border-secondary-500 hover:text-secondary-500 hover:bg-white {} }
    .btn-solid.btn-secondary.btn-hover-bg-lined {
      @apply after:border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:after:border-secondary-500 {} }
  .btn-solid.btn-white {
    @apply bg-white text-primary-500 border-white hover:text-white hover:bg-primary-500 hover:border-primary-500 {} }

.btn-lined {
  @apply bg-white text-primary-500 border-primary-500 {} }
  .btn-lined.btn-primary {
    @apply text-primary-500 border-primary-500 hover:bg-primary-500 {} }
    .btn-lined.btn-primary span {
      @apply text-primary-500 {} }
    .btn-lined.btn-primary:hover span, .btn-lined.btn-primary:hover i, .btn-lined.btn-primary:hover em {
      @apply text-white {} }

.btn-hover-bg-solid:hover {
  transform: translate(6px, -6px);
  box-shadow: -6px 6px 0 currentColor; }

.btn-hover-bg-lined::after {
  content: '';
  @apply bg-transparent border border-solid border-current rounded-0.75 absolute top-0 left-0 w-full h-full opacity-0 pointer-events-none -z-1 transition-all duration-200 ease-linear {} }

.btn-hover-bg-lined:hover {
  transform: translate(6px, -6px); }
  .btn-hover-bg-lined:hover::after {
    transform: translate(-6px, 6px);
    @apply opacity-100 {} }

#buttonMenu {
  @apply relative z-50 min-w-[28px] w-[28px] h-[32px] rotate-0 transition-all cursor-pointer border-none m-0 p-0 bg-none {} }
  #buttonMenu .line {
    @apply absolute block h-[2px] w-full bg-primary-500 rounded-full opacity-100 left-0 rotate-0 transition-all {} }
    #buttonMenu .line:nth-child(1) {
      @apply top-[4px] {} }
    #buttonMenu .line:nth-child(2) {
      @apply top-1/2 -translate-y-1/2 {} }
    #buttonMenu .line:nth-child(3) {
      @apply bottom-[4px] {} }
  #buttonMenu #pulseMe {
    @apply flex justify-center items-center absolute top-1/2 left-1/2 min-w-[34px] w-[30px] h-[34px] -translate-x-1/2 -translate-y-1/2 z-1 {} }
  #buttonMenu .bar {
    @apply bg-primary-500 rounded-0.5 absolute shadow-bar {} }
    #buttonMenu .bar.left {
      @apply w-px animate-left-bar {} }
    #buttonMenu .bar.top {
      @apply h-px animate-top-bar {} }
    #buttonMenu .bar.right {
      @apply w-px animate-right-bar {} }
    #buttonMenu .bar.bottom {
      @apply h-px animate-bottom-bar {} }
  #buttonMenu.open .line:nth-child(2), #buttonMenu:hover .line:nth-child(2) {
    @apply w-[15px] {} }
  #buttonMenu.open .bar, #buttonMenu:hover .bar {
    @apply hidden {} }

@screen xl {
  #buttonMenu {
    display: none !important; } }

.alert {
  @apply relative py-3 px-4 mb-1 border border-solid border-transparent rounded-md block leading-tight text-sm {} }
  .alert button {
    @apply hidden {} }
  .alert.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb; }
  .alert.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }

.backdrop.open {
  @apply opacity-100 pointer-events-auto {} }

input[type='text'],
input[type='email'],
input[type='url'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='week'],
[multiple],
textarea {
  @apply relative w-full bg-white border border-solid border-primary-500 h-10 pl-6.5 pr-12.5 py-1 text-sm text-desc font-normal rounded-5 {}  @apply focus:border-secondary-500 focus:ring-secondary-500 {}  @apply placeholder-shown:bg-white {}  @apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black-1000 {}  @apply read-only:bg-gray-e5 read-only:border-gray-cc read-only:text-black-1000 {} }

select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'><path stroke='%230B3F7E' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/></svg>");
  @apply relative w-full bg-white border border-solid border-primary-500 h-10 pl-6.5 pr-12.5 py-1 text-sm text-desc font-normal rounded-5 {}  @apply focus:border-secondary-500 focus:ring-secondary-500 {}  @apply placeholder-shown:bg-white {}  @apply disabled:bg-gray-e5 disabled:border-gray-cc disabled:text-black-1000 {} }

textarea {
  @apply h-17.5 py-2.5 {} }

input[type='file'] {
  @apply hidden {} }
  input[type='file'] ~ label {
    @apply relative w-max bg-gray-e5 border-none h-12 px-6 py-3 text-base text-primary-700 font-normal flex-center space-x-3 cursor-pointer m-0 transition-all duration-200 ease-linear xl:hover:bg-gray-cc {} }

.btn-submit,
input[type="submit"],
button[type="submit"] {
  @apply block h-9 px-6.5 space-x-2 text-base font-medium text-secondary-500 text-center border-primary-500 rounded-0.75 bg-primary-500 transition-all duration-200 ease-linear cursor-pointer xl:hover:bg-primary-700 {} }

.form-group {
  @apply mb-6 relative space-y-2 {} }
  .form-group label {
    @apply w-full block text-lg font-bold leading-[calc(22/18)] text-gray-33 {} }
  .form-group > span, .form-group > div > span {
    @apply text-xs text-red-900 italic block mt-0.5 font-primary font-normal {}    @apply before:hidden {} }

.input-group {
  @apply relative {} }
  .input-group .input-group-icon {
    @apply absolute top-1/2 -translate-y-1/2 right-4.5 z-1 text-xl text-primary-700 leading-none cursor-pointer m-0 {} }
  .input-group .toggle-password.active {
    @apply before:content-['\f06e'] {} }

.frm-btnwrap label.label {
  @apply hidden {} }

.frm-btnwrap .frm-btn-reset {
  @apply hidden {} }

.frm-captcha {
  @apply flex flex-wrap -mt-5 lg:flex-row-reverse lg:justify-end {} }
  .frm-captcha > div {
    @apply mt-5 {} }
  .frm-captcha label {
    @apply hidden {} }
  .frm-captcha .frm-captcha-input {
    @apply w-full lg:w-auto {} }
    .frm-captcha .frm-captcha-input input {
      @apply w-full xl:max-w-[calc(260/1920*100rem)] {} }
  .frm-captcha .RadCaptcha {
    @apply flex flex-col-reverse {}    @apply w-max {} }
    .frm-captcha .RadCaptcha > div {
      @apply w-full {} }
      .frm-captcha .RadCaptcha > div > div {
        @apply flex items-center {} }
    .frm-captcha .RadCaptcha > span {
      @apply text-[12px] text-secondary-700 italic block mt-0.5 font-primary font-normal {} }
  .frm-captcha .rcRefreshImage {
    font-size: 0;
    @apply before:font-awesome before:content-['\f2ea'] before:text-2xl before:text-blue-500 before:mx-5 xl:before:text-[calc(22/1920*100rem)] xl:before:ml-[calc(25.5/1920*100rem)] xl:before:mr-[calc(19.5/1920*100rem)] {} }

.modal {
  @apply max-w-6xl w-full p-0 rounded-5 {} }
  .modal .modal-content {
    @apply p-5 md:p-7.5 xl:p-10 {} }

.modal-recrutment-apply {
  @apply max-w-[calc(928/1920*100rem)] {} }
  .modal-recrutment-apply .modal-content {
    @apply xl:px-8 xl:py-12.5 {} }
  .modal-recrutment-apply iframe {
    @apply w-full h-[calc(400/1920*100rem)] {} }

.custom-scroll-dark::-webkit-scrollbar {
  width: 14px;
  height: 14px; }

.custom-scroll-dark::-webkit-scrollbar-button {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-track {
  background-color: #646464 !important; }

.custom-scroll-dark::-webkit-scrollbar-track-piece {
  background-color: #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #242424 !important;
  border: 2px solid #3e4346 !important; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(130deg, #696969 40%, rgba(255, 0, 0, 0) 41%), linear-gradient(230deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(0deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(310deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(50deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(180deg, #696969 40%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(330deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(90deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.custom-scroll-dark::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(150deg, #696969 40%, rgba(0, 0, 0, 0) 41%), linear-gradient(270deg, #696969 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #b6b6b6; }

.swiper .swiper-pagination {
  @apply bottom-5 xl:bottom-10 {} }
  .swiper .swiper-pagination .swiper-pagination-bullet {
    @apply opacity-100 rounded-full w-3 h-3 bg-transparent border-2 border-primary-500 transition-all duration-200 ease-linear mx-[calc((5/2)/1920*100rem)] {} }
    .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-primary-500 border-primary-500 {} }
  .swiper .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet {
    @apply border-white {} }
    .swiper .swiper-pagination.swiper-pagination-white .swiper-pagination-bullet.swiper-pagination-bullet-active {
      @apply bg-primary-500 border-primary-500 {} }

.swiper .swiper-button > * {
  @apply opacity-100 flex-center text-xl text-white transition-all duration-200 ease-linear {} }
  .swiper .swiper-button > *.swiper-button-disabled {
    @apply opacity-40 pointer-events-none {} }
  .swiper .swiper-button > *.swiper-button-lock {
    @apply opacity-0 pointer-events-none {} }

.swiper .button-absolute > * {
  @apply absolute top-1/2 -translate-y-1/2 z-10 {} }

.swiper .button-absolute .button-prev {
  @apply left-10 xl:left-15 {} }

.swiper .button-absolute .button-next {
  @apply right-10 xl:right-15 {} }

.swiper-relative {
  @apply relative z-2 pb-10 xl:pb-0 {} }
  .swiper-relative .swiper-pagination {
    @apply bottom-0 xl:hidden {} }
    .swiper-relative .swiper-pagination .swiper-pagination-bullet {
      @apply opacity-100 rounded-full w-3 h-3 bg-transparent border-2 border-primary-500 transition-all duration-200 ease-linear mx-[calc((5/2)/1920*100rem)] {} }
      .swiper-relative .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        @apply bg-primary-500 border-primary-500 {} }
  .swiper-relative .swiper-button > * {
    @apply w-10 h-10 rounded-full bg-transparent opacity-100 flex-center text-4xl leading-normal text-primary-500 transition-all duration-200 ease-linear xl:hover:bg-transparent xl:hover:text-primary-500 {} }
    .swiper-relative .swiper-button > *.swiper-button-disabled {
      @apply opacity-40 pointer-events-none {} }
    .swiper-relative .swiper-button > *.swiper-button-lock {
      @apply opacity-0 pointer-events-none {} }
  .swiper-relative .button-absolute > * {
    @apply absolute top-1/2 -translate-y-1/2 z-10 {} }
  .swiper-relative .button-absolute .button-prev {
    @apply -left-10 xl:-left-15 {} }
  .swiper-relative .button-absolute .button-next {
    @apply -right-10 xl:-right-15 {} }
  .swiper-relative .swiper-button {
    @apply hidden xl:block {} }

.equal-swiper .swiper-slide {
  @apply h-auto {} }
  .equal-swiper .swiper-slide > * {
    @apply h-full {} }

.equal-height .col > * {
  @apply h-full {} }

.vision-mission-item {
  @apply text-center max-w-[calc(280/1920*100rem)] mx-auto {} }
  .vision-mission-item .icon {
    @apply w-25 h-25 mx-auto flex-center {} }
    .vision-mission-item .icon img {
      @apply w-full h-full object-contain {} }
  .vision-mission-item .caption {
    @apply mt-7.5 {} }
  .vision-mission-item .title {
    @apply heading-4-title {}    @apply font-bold text-primary-500 leading-tight {} }
  .vision-mission-item .desc {
    @apply body-2-text {}    @apply mt-5 font-medium leading-1.44 {} }

.core-value-item {
  @apply relative {} }
  .core-value-item::before {
    content: '';
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, #000000 100%);
    @apply absolute-center w-full h-full z-1 pointer-events-none {} }
  .core-value-item .img-scale {
    @apply pt-[calc(302/310*100%)] {} }
  .core-value-item .title {
    @apply body-3-text {}    @apply absolute left-0 w-full bottom-0 z-1 px-3.75 py-5 text-center text-white font-normal leading-normal z-2 {} }

@screen xl {
  .core-value-item {
    @apply h-[calc(304/1920*100rem)] {} }
    .core-value-item .image {
      @apply h-full pt-0 {} }
    .core-value-item:hover .image img {
      @apply scale-110 {} } }

.about-2 {
  @apply xl:pb-0 {} }
  .about-2 .global-desc {
    @apply mt-7.5 font-medium leading-1.44 {} }
  .about-2 .vision-mission-wrap {
    @apply mt-10 xl:mt-20 {} }

.about-3 {
  background-size: inherit;
  @apply bg-bottom xl:bg-cover xl:pt-20 {} }
  .about-3 .global-desc {
    @apply body-2-text {}    @apply font-medium leading-1.44 {} }
  .about-3 .block-content {
    @apply py-15 xl:pt-27 xl:pb-37 {} }

@screen lg {
  .about-4 .core-value-item:first-child {
    @apply col-span-2 h-[calc(304/1920*100rem)] {} }
    .about-4 .core-value-item:first-child .image {
      @apply h-full pt-0 {} } }

@screen xl {
  .about-4 .core-value-item:first-child {
    @apply row-span-2 col-span-1 {}    @apply h-full {} }
  .about-4 .core-value-item:nth-child(3) {
    @apply col-span-2 {} }
  .about-4 .core-value-item:last-child {
    @apply col-span-2 {} } }

.about-5 .blog-list {
  @apply mt-10 xl:mt-15 {} }

.about-5 .blog-item.is-hor + .blog-item {
  @apply mt-7.5 {} }

.blog-detail-section .block-content {
  @apply xl:pr-7.5 {} }

.blog-detail-section .date {
  @apply body-4-text {}  @apply font-medium text-gray-66 leading-normal {} }

.blog-detail-section .blog-detail-title {
  @apply text-2xl leading-1.2 font-bold text-gray-33 mt-5 pb-5 mb-5 border-b border-b-gray-100 md:text-3xl lg:text-4xl xl:text-40 {} }

.blog-detail-section .blog-list {
  @apply space-y-7.5 mt-5 {} }

.blog-detail-section .social-list {
  @apply mt-7.5 {} }

@screen xl {
  .blog-detail-section .social-list {
    @apply absolute right-full top-0 mt-0 flex-col mr-7.5 {} } }

.other-title {
  @apply heading-5-title {}  @apply font-normal text-primary-500 leading-1.33 {} }

.blog-item .img-scale {
  @apply rounded-3 pt-[calc(218/390*100%)] {} }

.blog-item .blog-caption {
  @apply mt-4 {} }

.blog-item .blog-date {
  @apply body-4-text {}  @apply font-medium text-gray-66 leading-normal {} }

.blog-item .blog-title {
  @apply body-2-text {}  @apply mt-4 font-medium leading-1.44 text-gray-33 uppercase line-clamp-2 hover:text-primary-500 {} }

.blog-item .blog-brief {
  @apply body-3-text {}  @apply mt-5 font-normal text-gray-66 leading-normal line-clamp-3 {} }

@screen md {
  .blog-item.is-hor {
    @apply flex {} }
    .blog-item.is-hor .blog-image {
      @apply w-[calc(288/1920*100rem)] {} }
    .blog-item.is-hor .blog-caption {
      @apply flex-1 pl-5 mt-0 pt-3 {} }
    .blog-item.is-hor .blog-title {
      @apply mt-3 {} }
    .blog-item.is-hor.is-small {
      @apply pb-0 {} }
      .blog-item.is-hor.is-small .blog-image {
        @apply w-[calc(178/1920*100rem)] {} }
      .blog-item.is-hor.is-small .img-scale {
        @apply pt-[calc(100/178*100%)] {} }
      .blog-item.is-hor.is-small .blog-caption {
        @apply pl-7.5 pt-0 {} }
      .blog-item.is-hor.is-small .blog-title {
        @apply text-lg line-clamp-3 mt-3 leading-1.44 {} } }

@screen xl {
  .blog-item {
    @apply min-h-[calc(352/1920*100rem)] pb-5 {} }
    .blog-item.is-hor {
      @apply min-h-0 {} }
    .blog-item:hover .blog-image img {
      @apply scale-110 {} } }

.blog-list-section {
  @apply relative z-1 {} }

@screen xl {
  .blog-list-section::before {
    content: '';
    @apply bg-[url('../img/bg-blog-1.png')] bg-contain bg-no-repeat bg-center {}    @apply absolute top-25 left-0 -z-1 pointer-events-none w-[calc(241/1920*100rem)] h-[calc(629/1920*100rem)] {} }
  .blog-list-section::after {
    content: '';
    @apply bg-[url('../img/bg-blog-2.png')] bg-contain bg-no-repeat bg-center {}    @apply absolute top-[calc(320/1920*100rem)] right-0 -z-1 pointer-events-none w-[calc(178/1920*100rem)] h-[calc(520/1920*100rem)] {} }
  .blog-list-section .container {
    @apply relative z-2 {} } }

.banner-child {
  @apply relative {} }
  .banner-child .img-scale {
    @apply pt-full md:pt-[calc(720/1920*100%)] {} }

.home-banner {
  @apply relative {} }
  .home-banner .img-scale {
    @apply pt-full md:pt-[calc(720/1920*100%)] {} }
  .home-banner .next-section {
    @apply absolute left-1/2 -translate-x-1/2 -bottom-7.5 z-10 w-15 h-15 rounded-full overflow-hidden flex-center cursor-pointer xl:w-24 xl:h-24 xl:-bottom-12 {} }
    .home-banner .next-section img {
      @apply w-full h-full object-cover {} }

.global-breadcrumb {
  @apply bg-gray-50/50 {} }
  .global-breadcrumb .breadcrumb {
    @apply flex flex-wrap items-center py-1.75 {} }
    .global-breadcrumb .breadcrumb a {
      @apply block text-lg leading-normal font-medium text-gray-300 hover:text-primary-500 {} }
    .global-breadcrumb .breadcrumb li {
      @apply px-2 h-full flex-center last:after:hidden {} }
      .global-breadcrumb .breadcrumb li::after {
        content: '';
        @apply flex-center w-px h-3.5 bg-gray-300 ml-3.75 {} }
      .global-breadcrumb .breadcrumb li:first-child a {
        font-size: 0; }
        .global-breadcrumb .breadcrumb li:first-child a span {
          @apply hidden {} }
        .global-breadcrumb .breadcrumb li:first-child a::before {
          @apply font-awesome content-['\f015'] text-base flex-center w-6 h-6 {} }

.banner-child + .global-breadcrumb {
  @apply pt-0 {} }

.cmsadminpanel .ddviewmode {
  @apply h-[24px] text-[12px] py-1 px-2 pr-5 leading-none {} }

.modulepager {
  @apply mt-7.5 xl:mt-10 {} }
  .modulepager .pagination {
    @apply flex-center flex-wrap gap-5 {} }
  .modulepager a, .modulepager span {
    @apply relative z-1 w-10 h-10 rounded-2 flex-center text-base text-primary-500 font-normal transition-all duration-300 ease-linear border border-primary-500 xl:w-12.5 xl:h-12.5 xl:text-2xl {} }
  .modulepager li.active a, .modulepager li.active span, .modulepager li:hover a, .modulepager li:hover span {
    @apply text-white bg-primary-500 {} }

.searchbox {
  @apply relative {} }
  .searchbox input {
    @apply w-full h-8 border border-gray-100 rounded-full pl-3 pr-12 text-sm text-gray-400 bg-white {}    @apply placeholder-shown:bg-white {}    @apply focus:ring-secondary-300 focus:outline-none focus:shadow-none focus:border-secondary-300 {} }
  .searchbox button {
    @apply absolute top-1/2 -translate-y-1/2 right-2 z-1 w-10 h-10 flex-center text-base text-gray-400 {} }

.suggestsearch {
  display: none !important; }

.searchresults .search-page {
  @apply py-15 xl:py-20 {} }

.searchresults .page-header h1 {
  @apply uppercase {}  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px {} }

.searchresults .searchresultsummary {
  @apply text-[11px] xl:text-[13px] mb-3 {} }

.searchresults .searchcontrols .form-inline {
  @apply relative {} }

.searchresults .searchcontrols .form-group {
  @apply hidden first:block last:block last:absolute last:w-12.5 last:h-full last:top-0 last:right-0 last:z-1 last:text-[0] last:mb-0 last:flex-center {}  @apply last:before:absolute last:before:w-full last:before:h-full last:before:font-awesome last:before:content-['\f002'] last:before:text-blue-500 last:before:text-2xl last:before:flex-center last:before:pointer-events-none {} }
  .searchresults .searchcontrols .form-group:first-child input {
    @apply pr-14 {} }
  .searchresults .searchcontrols .form-group:last-child input {
    @apply z-2 w-12.5 h-full relative top-0 right-0 opacity-0 {} }

.searchresults .searchresults > .modulepager {
  @apply hidden {} }
  .searchresults .searchresults > .modulepager:last-child {
    @apply block {} }

.searchresults .searchresultlist {
  @apply px-1 xl:px-5 {} }

.searchresults .searchresult {
  @apply mt-10 {} }
  .searchresults .searchresult h3 a {
    @apply text-xl hover:underline hover:text-white {} }
  .searchresults .searchresult .searchresultdesc {
    @apply mt-1 text-base {} }
  .searchresults .searchresult hr {
    @apply hidden {} }

.login-box {
  @apply py-25 px-10 xl:py-30 {} }
  .login-box .card {
    @apply bg-gray-f5 mx-auto p-5 {} }

@screen xl {
  .login-box .card {
    @apply max-w-[calc(750/1920*100rem)] px-[calc(95/1920*100rem)] pt-7 pb-15 {} } }
  .login-box .input-group {
    @apply mb-5 {} }
    .login-box .input-group input {
      @apply border-white bg-white w-full rounded-1.25 h-12.5 text-gray-33 text-base px-5 placeholder:text-gray-99 {} }
  .login-box .input-group-append {
    @apply hidden {} }
  .login-box .btn.btn-primary.btn-block, .login-box .btn.btn-default.createuserbutton {
    @apply cursor-pointer bg-black-1000 border-black-1000 transition-all h-11 text-center text-white font-normal uppercase text-sm px-10 rounded-full xl:min-w-[calc(183/1920*100rem)] xl:hover:bg-primary-700 xl:hover:border-primary-700 {} }
  .login-box .social-login {
    @apply hidden {} }
  .login-box .row.items-center {
    @apply justify-between {} }
  .login-box a {
    @apply text-base xl:text-sm {} }

.wrap-register > div > div {
  @apply shadow rounded-5 overflow-hidden bg-white mx-auto p-5 {} }

@screen xl {
  .wrap-register > div > div {
    @apply max-w-[calc(600/1920*100rem)] p-7 {} } }

.wrap-register .settingrow .RadInput .riTextBox {
  @apply w-full bg-gray-66 border border-solid border-gray-ee rounded-1.25 h-12.5 px-5 text-base text-gray-22 leading-normal xl:text-sm {} }

.login-logo, .pagetitle {
  @apply text-2xl font-light text-secondary-700 uppercase leading-tight text-center mb-8 {} }

.login-box-msg {
  @apply text-base leading-[1.38] text-secondary-700 font-normal mb-5 {} }

.icheck-primary {
  @apply form-group text-base gap-3 flex items-center {} }
  .icheck-primary label {
    @apply mt-0 {} }

.login-download-section .login-box {
  @apply p-0 xl:p-0 {} }
  .login-download-section .login-box .btn-download-submit {
    @apply w-full {} }

.login-download-section .module-description {
  @apply text-base text-secondary-700 font-normal {} }

.login-card-body .flex a {
  @apply hover:text-primary-50 mr-5 {} }

.dealer-gallery-item {
  @apply rounded-2 overflow-hidden {} }
  .dealer-gallery-item img {
    @apply w-full h-full object-contain {} }
  .dealer-gallery-item iframe {
    @apply w-0 min-w-full h-full min-h-[calc(375/1920*100rem)] {} }

.dealer-gallery-list {
  @apply gap-2.5 flex flex-col-reverse md:flex-col {} }

.dealer-related-item {
  @apply flex {} }
  .dealer-related-item .image {
    @apply w-1/2 md:w-[calc(212/1920*100rem)] {} }
  .dealer-related-item .img-scale {
    @apply pt-[calc(120/212*100%)] rounded-2 {} }
  .dealer-related-item .caption {
    @apply flex-1 pl-4 {} }
  .dealer-related-item .title {
    @apply body-2-text {}    @apply font-bold text-primary-500 leading-1.44 mb-3 {} }
  .dealer-related-item .location {
    @apply body-2-text {}    @apply font-medium text-gray-66 {} }
    .dealer-related-item .location li {
      @apply flex items-center gap-3 {} }
    .dealer-related-item .location i, .dealer-related-item .location em {
      @apply w-4 text-black-1000 {} }
    .dealer-related-item .location p {
      @apply flex-1 {} }

.dealer-related-list {
  @apply space-y-7.5 {} }

.dealer-detail-section .global-title {
  @apply normal-case {} }

.dealer-detail-section .shared-wrap {
  @apply body-2-text {}  @apply font-medium leading-1.44 text-gray-33 {}  @apply flex items-center gap-5.5 mt-5 pl-2 {} }

.dealer-detail-section .dealer-info {
  @apply body-2-text {}  @apply mt-5.5 text-gray-66 font-medium leading-1.44 space-y-3 {} }
  .dealer-detail-section .dealer-info ul {
    @apply space-y-3 {} }
  .dealer-detail-section .dealer-info li {
    @apply flex items-center gap-3 {} }
    .dealer-detail-section .dealer-info li i, .dealer-detail-section .dealer-info li em {
      @apply w-4 text-black-1000 {} }
    .dealer-detail-section .dealer-info li p {
      @apply flex-1 {} }

.dealer-detail-section .dealer-related {
  @apply mt-5 pt-5 border-t border-t-gray-100 {} }

.dealer-detail-section .dealer-related-title {
  @apply body-2-text {}  @apply font-bold text-primary-500 leading-1.44 mb-7.5 {} }

.gmaps-dealer-wrap {
  filter: drop-shadow(0px -4px 5px rgba(126, 157, 93, 0.25)) drop-shadow(0px 4px 5px rgba(126, 157, 93, 0.25));
  @apply bg-white rounded-4 {} }
  .gmaps-dealer-wrap .dealer-maps {
    @apply h-full min-h-[calc(300/1920*100rem)] {} }
    .gmaps-dealer-wrap .dealer-maps iframe {
      @apply w-full h-full min-w-full {} }
  .gmaps-dealer-wrap .dealer-filter {
    @apply p-5 xl:pt-12 xl:pl-7.5 xl:pr-11 xl:pb-4 {} }
  .gmaps-dealer-wrap .wrap-form {
    @apply space-y-3 {} }
  .gmaps-dealer-wrap .form-group {
    @apply m-0 {} }
    .gmaps-dealer-wrap .form-group select {
      @apply bg-green-100 h-12.5 border-none px-6 text-lg font-normal text-gray-54 rounded-2 {} }
  .gmaps-dealer-wrap .frm-btnwrap .btn-submit, .gmaps-dealer-wrap .frm-btnwrap input[type='submit'], .gmaps-dealer-wrap .frm-btnwrap button[type='submit'] {
    @apply min-w-[calc(182/1920*100rem)] text-white h-11 rounded-0.75 text-xl hover:text-white {} }
  .gmaps-dealer-wrap #list {
    @apply mt-9 max-h-[calc(313/1920*100rem)] custom-scroll {} }
  .gmaps-dealer-wrap li {
    @apply py-5 text-base leading-tight text-desc flex gap-2 font-secondary border-b border-b-desc/10 cursor-pointer transition-all duration-200 ease-linear xl:hover:bg-gray-99/10 {} }
    .gmaps-dealer-wrap li i, .gmaps-dealer-wrap li em {
      @apply text-base leading-none text-desc mt-1 {} }
    .gmaps-dealer-wrap li.active {
      @apply bg-gray-99/10 {} }

.dealer-zone-wrap {
  @apply p-7.5 rounded-3 border-2 border-gray-50 {} }
  .dealer-zone-wrap .zone-title {
    @apply heading-3-title {}    @apply font-bold leading-tight text-gray-33 {} }
  .dealer-zone-wrap .zone-list {
    @apply space-y-5 mt-5 xl:px-3.75 {} }
    .dealer-zone-wrap .zone-list a.active {
      @apply bg-primary-500 text-white {} }
      .dealer-zone-wrap .zone-list a.active span {
        @apply text-white {} }
    .dealer-zone-wrap .zone-list li.active a {
      @apply bg-primary-500 text-white {} }
      .dealer-zone-wrap .zone-list li.active a span {
        @apply text-white {} }

.dealer-item {
  @apply relative {} }
  .dealer-item::before {
    content: '';
    @apply absolute-center w-full h-full border border-gray-50 rounded-5 pointer-events-none {} }
  .dealer-item .img-scale {
    @apply pt-[calc(250/443*100%)] rounded-5 {} }
  .dealer-item .dealer-caption {
    @apply mt-3 px-5 pb-7.5 xl:pb-10 {} }
  .dealer-item .dealer-title {
    @apply text-xl font-medium leading-1.4 text-gray-33 hover:text-primary-500 {} }
  .dealer-item .button {
    @apply mt-3 -mx-5 {} }
    .dealer-item .button .btn {
      @apply w-full {} }
  .dealer-item .dealer-info {
    @apply mt-5 pt-3 border-t border-t-gray-50 {} }
  .dealer-item ul {
    @apply space-y-5 {} }
  .dealer-item li {
    @apply flex text-lg font-medium leading-1.44 text-gray-66 {} }
    .dealer-item li i, .dealer-item li em {
      @apply text-base leading-none text-black-1000 w-6.5 mt-1 {} }

.load-item-init .load-item {
  @apply hidden {} }

.franchising-conditions-item .icon {
  @apply w-17.5 h-17.5 mx-auto {} }

.franchising-conditions-item .title {
  @apply body-2-text {}  @apply mt-7.5 font-medium text-gray-33 {} }

.franchising-3 .global-desc {
  @apply body-2-text {}  @apply font-medium leading-1.44 text-gray-33 {} }
  .franchising-3 .global-desc ul {
    @apply pl-5 list-disc space-y-1.5 {} }
  .franchising-3 .global-desc li {
    @apply marker:text-secondary-500 marker:text-xl {} }

.franchising-3 .image {
  @apply max-w-[calc(600/1920*100rem)] mx-auto {} }

.franchising-3 .img-scale {
  @apply pt-[calc(357/600*100%)] rounded-2 {} }

.franchising-4 .image {
  @apply px-3.75 {} }

.franchising-4 .franchising-contact {
  @apply mt-10 text-center text-base lg:mt-15 lg:text-xl lg:text-2xl {} }
  .franchising-4 .franchising-contact h3 {
    @apply body-1-text {}    @apply font-medium text-primary-500 uppercase mb-5 md:text-3xl xl:text-4xl {} }
  .franchising-4 .franchising-contact a {
    @apply font-bold text-primary-500 hover-underline {} }

header {
  @apply bg-white fixed top-0 left-0 w-full right-0 z-1000 {} }
  header::before {
    content: '';
    @apply absolute bottom-0 left-0 w-full h-px bg-[#EAEBED] pointer-events-none {} }
  header .header-wrap {
    @apply flex justify-between items-center {} }
  header .logo {
    @apply h-25 py-2.5 xl:h-30 xl:py-5 {} }
    header .logo .ModuleContent {
      @apply flex h-full {} }
      header .logo .ModuleContent a {
        @apply flex-center {} }
    header .logo img {
      @apply w-full h-full object-contain {} }
  header .header-top {
    @apply py-3.5 justify-end items-center gap-6 hidden xl:flex {} }
  header .header-bot {
    @apply flex items-center gap-5 xl:gap-0 {} }
    header .header-bot .navbar-nav {
      @apply hidden xl:block {} }
    header .header-bot .button-search {
      @apply text-lg w-10 h-10 flex-center xl:hidden {} }

@screen xl {
  header .header-bot {
    @apply relative {} }
    header .header-bot::before {
      content: '';
      @apply absolute top-0 left-0 w-full h-px bg-[#EAEBED] pointer-events-none {} } }
  header .language {
    @apply flex items-center gap-3 {} }
    header .language em, header .language i {
      @apply text-base text-primary-500 {} }
    header .language ul {
      @apply flex-center gap-2 {} }
      header .language ul a {
        @apply text-gray-500 font-normal uppercase hover:text-primary-500 {} }
    header .language li {
      @apply relative {} }
      header .language li + li:before {
        content: '';
        @apply h-3 w-0.5 bg-gray-100 absolute top-1/2 -translate-y-1/2 -left-1 {} }
      header .language li.active a {
        @apply text-light-green font-semibold {} }
  header .header-line-ver {
    @apply w-px h-4 bg-gray-100 {} }
  header .main-menu {
    @apply flex justify-end items-center gap-7.5 xl:gap-[35px] 2xl:gap-[calc(35/1920*100rem)] {} }
    header .main-menu a {
      @apply text-gray-33 font-bold uppercase text-center block py-4.5 hover-underline hover:text-light-green {} }
    header .main-menu li.active a {
      @apply text-light-green hover-underline-active {} }
  header .searchbox {
    @apply w-[calc(192/1920*100rem)] {} }
  header .btn-fanpage {
    @apply bg-transparent hover:bg-primary-50 -mr-2.5 {} }

.btn-fanpage {
  @apply px-2.5 py-1 rounded-1.25 bg-white flex-center text-gray-400 font-semibold text-base leading-normal bg-primary-50 {} }
  .btn-fanpage span, .btn-fanpage em, .btn-fanpage i {
    @apply text-light-green {} }
  .btn-fanpage em {
    @apply mr-2 {} }

.mobile-wrap {
  @apply fixed top-0 w-screen h-screen bg-white shadow z-998 pt-32 pl-3.75 pb-3.75 max-w-xs -left-full opacity-0 transition-all duration-500 ease-in-out pointer-events-none xl:hidden {} }
  .mobile-wrap .navbar-nav-list {
    @apply pr-3.75 mb-10 {} }
  .mobile-wrap .main-menu {
    @apply flex flex-col gap-2 {} }
    .mobile-wrap .main-menu a {
      @apply text-gray-33 font-bold uppercase block py-1 hover:text-primary-500 {} }
    .mobile-wrap .main-menu li.active a {
      @apply text-primary-500 pl-3.75 border-l-2 border-l-primary-500 {} }
  .mobile-wrap .btn-fanpage {
    @apply mr-5 w-max px-5 py-1.5 {} }
  .mobile-wrap.open {
    @apply left-0 opacity-100 pointer-events-auto {} }

.search-wrap {
  @apply fixed top-1/4 w-screen px-5 z-998 opacity-0 scale-80 transition-all duration-500 ease-in-out pointer-events-none xl:hidden {} }
  .search-wrap .searchbox input {
    @apply h-10 pl-5 text-lg {} }
  .search-wrap .searchbox button {
    @apply text-xl {} }
  .search-wrap.open {
    @apply scale-100 opacity-100 pointer-events-auto {} }

.home-1 {
  @apply pt-15 lg:pb-50 xl:pb-50 {} }
  .home-1 .block-content {
    @apply mt-10 xl:mt-[calc(115/1920*100rem)] {} }
  .home-1 .global-desc {
    @apply max-w-md lg:max-w-[calc(475/1920*100rem)] {} }
  .home-1 .sunday-info {
    box-shadow: 0px -4px 4px rgba(224, 224, 224, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1);
    @apply flex flex-wrap gap-5 bg-secondary-500 rounded-2 py-5 px-12.5 text-center mt-7.5 max-w-md md:text-left md:py-3 md:px-5 xl:mt-10 xl:pl-6 xl:pr-8 xl:gap-7.5 lg:max-w-[calc(560/1920*100rem)] {} }
    .home-1 .sunday-info > * {
      @apply w-full md:w-auto {} }
  .home-1 .number {
    @apply flex items-start text-4xl text-primary-500 font-medium uppercase leading-tight justify-center md:justify-start md:text-3xl xl:text-4xl {} }
    .home-1 .number span:not(.count-up) {
      @apply text-lg md:text-xl xl:text-2xl {} }
  .home-1 li {
    @apply relative text-base leading-tight text-primary-500 tracking-tight font-normal pr-5 xl:pr-7.5 last:pr-0 last:before:hidden {} }
    .home-1 li strong {
      @apply text-lg font-medium block whitespace-nowrap {} }

@screen md {
  .home-1 li::before {
    content: '';
    @apply absolute right-0 top-1/2 -translate-y-1/2 w-px h-5/6 bg-gradient-to-b from-white/0 via-primary-500 to-white/0 {} } }
  @media (max-width: 1023.89px) {
    .home-1 {
      background: linear-gradient(179.92deg, rgba(217, 255, 176, 0) 0.07%, rgba(217, 255, 176, 0.5) 101.45%) !important; } }

.home-3 {
  @apply relative z-2 lg:px-15 xl:pt-30 xl:pb-0 {} }
  .home-3::before {
    content: '';
    background-image: radial-gradient(87.32% 87.32% at 60.6% 53.77%, #086812 0%, #01481D 100%);
    @apply absolute top-0 left-1/2 -translate-x-1/2 w-[calc(100%-(10/1920*100rem))] h-full -z-1 rounded-10 lg:hidden {} }
  .home-3 .bg-home-3 {
    @apply lg:bg-[url('../img/home-bg-1.png')] lg:bg-bottom lg:bg-no-repeat lg:bg-cover lg:rounded-[55px] {} }
  .home-3 .image {
    @apply text-right {} }
    .home-3 .image img {
      @apply lg:w-full lg:h-full lg:object-contain {} }

@screen lg {
  .home-3 .image {
    @apply absolute right-1 bottom-7 max-w-[calc(490/1920*100rem)] w-full {} } }
  .home-3 .global-desc {
    @apply leading-tight {} }
  .home-3.franchising-1 .bg-home-3 {
    @apply lg:min-h-[calc(477/1920*100rem)] lg:flex-center {} }

@screen lg {
  .home-3.franchising-1 .bg-home-3 {
    background: radial-gradient(87.32% 87.32% at 60.6% 53.77%, #086812 0%, #01481D 100%); } }

@screen lg {
  .home-3.franchising-1 .image {
    @apply max-w-none -bottom-15 -right-15 {} } }

.home-4 {
  @apply pb-0 {} }

.recruitment-detail-wrap {
  box-shadow: 2px 6px 15px rgba(0, 0, 0, 0.1);
  @apply bg-white rounded-5 p-5 lg:pb-10 xl:pb-12.5 {} }
  .recruitment-detail-wrap .recruitment-detail-title {
    @apply pb-5 border-b border-b-gray-50 mb-5 lg:px-5 xl:text-4xl {} }
  .recruitment-detail-wrap .recruitment-block-content {
    @apply space-y-5 lg:px-5 lg:space-y-6 {} }
  .recruitment-detail-wrap .global-title {
    @apply normal-case {} }
  .recruitment-detail-wrap .block-content {
    @apply font-medium text-lg text-gray-33 {} }
    .recruitment-detail-wrap .block-content h2 {
      @apply font-bold mb-5 xl:text-32 {} }
    .recruitment-detail-wrap .block-content h3 {
      @apply font-medium mb-5 xl:text-2xl {} }
    .recruitment-detail-wrap .block-content * + h2 {
      @apply mt-5 xl:mt-5.5 {} }
    .recruitment-detail-wrap .block-content * + h3 {
      @apply mt-5 xl:mt-6.5 {} }
  .recruitment-detail-wrap .button {
    @apply lg:px-5 xl:pl-15 xl:mt-15 {} }

.recruitment-other-item {
  @apply bg-gray-f5 p-5 rounded-1 {} }
  .recruitment-other-item .title {
    @apply body-2-text {}    @apply font-bold text-gray-33 hover:text-primary-500 leading-1.44 pb-3.75 mb-3.75 relative {} }
    .recruitment-other-item .title::before {
      content: '';
      @apply absolute left-0 top-full w-9 h-0.5 bg-gray-33 {} }
  .recruitment-other-item .caption {
    @apply body-2-text {}    @apply font-medium text-gray-33 leading-1.44 {} }
    .recruitment-other-item .caption .end-date {
      @apply text-secondary-500 {} }

.recruitment-detail-other .other-title {
  @apply heading-5-title {}  @apply font-normal text-primary-500 leading-1.33 mb-7.5 lg:mb-11 {} }

.recruitment-detail-other .recruitment-list {
  @apply space-y-7.5 {} }

.recruitment-filter {
  @apply bg-gray-50/50 rounded-2 p-5 lg:flex lg:items-center lg:gap-5 xl:gap-0 xl:pl-10 xl:pr-2.5 xl:py-3 {} }
  .recruitment-filter .form-group {
    @apply flex flex-col gap-1 space-y-0 lg:gap-5 lg:mb-0 lg:flex-row lg:items-center {} }
    .recruitment-filter .form-group label {
      @apply text-lg font-medium text-gray-33 leading-1.44 lg:w-auto {} }
    .recruitment-filter .form-group select {
      @apply h-[36px] bg-white border-white rounded-1 px-5.5 text-lg lg:w-[calc(380/1920*100rem)] lg:max-w-full {} }
    .recruitment-filter .form-group .btn-submit {
      @apply rounded-2 h-[32px] text-base flex-center lg:px-2.5 hover:text-white {} }
      .recruitment-filter .form-group .btn-submit:hover span {
        @apply text-white {} }
    .recruitment-filter .form-group + .form-group:not(.form-submit) {
      @apply xl:ml-20 {} }
    .recruitment-filter .form-group + .form-group.form-submit {
      @apply xl:ml-5.5 {} }

.table-responsive table {
  @apply w-full rounded-2 overflow-hidden {} }

.table-responsive th {
  @apply border border-gray-50 bg-primary-500 text-lg font-medium text-white text-center px-2.5 py-2.75 {} }

.table-responsive td {
  @apply border border-gray-50 text-lg font-medium text-gray-33 text-center px-2.5 py-2.75 {} }

.table-responsive a {
  @apply hover:text-green-500 {} }

@media (max-width: 767.89px) {
  .table-responsive {
    @apply rounded-none {} }
    .table-responsive thead {
      @apply hidden {} }
    .table-responsive table, .table-responsive tbody, .table-responsive tr, .table-responsive td {
      @apply block w-full {} }
    .table-responsive tbody {
      @apply space-y-5 {} }
    .table-responsive tr {
      @apply border border-gray-50 rounded-2 p-5 space-y-1 {} }
    .table-responsive td {
      padding: 0 !important;
      @apply border-none text-left font-normal {} }
      .table-responsive td::before {
        content: attr(data-title);
        @apply font-medium mr-1 {} }
    .table-responsive a {
      @apply font-medium {} } }

.recruitment-list .recruitment-wrap {
  @apply mt-10 xl:mt-12.5 {} }

.recruitment-list .table-responsive th:nth-child(2), .recruitment-list .table-responsive th:nth-child(4), .recruitment-list .table-responsive td:nth-child(2), .recruitment-list .table-responsive td:nth-child(4) {
  @apply text-left px-10 {} }

.apply-frm .apply-title {
  @apply text-2xl mb-5 font-bold text-gray-33 md:text-3xl xl:text-4xl {} }

.apply-frm .form-group {
  @apply space-y-0 mb-6 {} }
  .apply-frm .form-group label {
    @apply hidden {} }
  .apply-frm .form-group input {
    @apply rounded-1 border-[#E0E0E0] xl:h-10 {} }
  .apply-frm .form-group textarea {
    @apply rounded-1 border-[#E0E0E0] xl:h-25 {} }
  .apply-frm .form-group .btn-submit {
    background-image: url("../img/arrow-right-yellow.png");
    background-position: 85% center;
    @apply h-9 text-base font-medium w-25 rounded-1 pl-5 pr-10 min-w-0 bg-no-repeat {} }

.apply-frm .attachfile {
  @apply sm:flex sm:items-center sm:gap-5 {} }
  .apply-frm .attachfile > * {
    @apply sm:flex-1 {} }
  .apply-frm .attachfile label {
    @apply block text-base text-gray-66 font-bold uppercase {} }
  .apply-frm .attachfile input[type='file'] {
    @apply block {} }
  .apply-frm .attachfile .RadUpload {
    @apply w-full font-primary {} }

.apply-frm .wrap-form .row {
  @apply sm:-mx-4 {} }
  .apply-frm .wrap-form .row > * {
    @apply sm:px-4 {} }

.sitemap {
  @apply py-15 {} }

.sitemap-heading h1 {
  @apply global-title text-center uppercase mx-auto {}  @apply relative w-max max-w-full pb-1.5 before:absolute before:bottom-0 before:left-0 before:w-full before:h-px before:bg-gradient {} }

.AspNet-TreeView > ul {
  @apply sm:flex sm:flex-wrap xl:overflow-hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root {
  @apply pb-5 ml-2 first:ml-0 xl:ml-0 xl:pr-5 xl:relative xl:overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root a {
    @apply inline-flex items-center px-7 py-3 text-xl bg-blue-500 text-white rounded-full max-w-full relative z-2 xl:text-sm xl:px-5 xl:hover:bg-secondary-700 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > a {
    @apply before:absolute before:left-full before:w-[120%] before:h-px before:bg-gray-33 before:-z-1 {} }
  .AspNet-TreeView .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
    @apply hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root li {
    @apply pl-3 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li a {
      @apply bg-blue-500/90 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li a {
      @apply bg-blue-500/75 {} }
    .AspNet-TreeView .AspNet-TreeView-Root li li li a {
      @apply bg-blue-500/60 {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li:last-child {
    @apply before:absolute before:w-px before:h-full before:left-0 before:top-[65%] before:-translate-y-1/2 before:bg-white before:z-1 before:pointer-events-none {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Leaf:last-child {
    @apply before:top-full {} }
  .AspNet-TreeView .AspNet-TreeView-Root:last-child > a {
    @apply before:hidden {} }

.AspNet-TreeView .AspNet-TreeView-Root > ul, .AspNet-TreeView .AspNet-TreeView-Leaf > ul, .AspNet-TreeView .AspNet-TreeView-Parent > ul {
  @apply ml-5 overflow-hidden {} }
  .AspNet-TreeView .AspNet-TreeView-Root > ul > li, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li {
    @apply mt-2 relative z-1 {}    @apply after:absolute after:w-px after:h-[200%] after:left-0 after:top-1/2 after:-translate-y-full after:bg-gray-33 after:pointer-events-none {} }
    .AspNet-TreeView .AspNet-TreeView-Root > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Leaf > ul > li > a, .AspNet-TreeView .AspNet-TreeView-Parent > ul > li > a {
      @apply relative {}      @apply before:absolute before:w-3 before:-z-1 before:h-px before:bg-gray-33 before:top-1/2 before:-translate-y-1/2 before:right-full before:pointer-events-none {} }

.menu-detail-section .product-detail-top .product-image {
  @apply bg-gray-ef rounded-5 overflow-hidden {} }

.menu-detail-section .product-detail-top .img-scale {
  @apply pt-[calc(711/600*100%)] {} }
  .menu-detail-section .product-detail-top .img-scale img {
    @apply absolute-center max-w-[75%] max-h-[75%] object-contain {} }

.menu-detail-section .product-detail-top .swiper-relative {
  @apply mt-10 px-15 pb-0 {} }

.menu-detail-section .product-detail-top .swiper-thumbs .product-image {
  @apply rounded-3 {} }

.menu-detail-section .product-detail-top .swiper-button {
  @apply block {} }
  .menu-detail-section .product-detail-top .swiper-button > * {
    @apply absolute top-1/2 -translate-y-1/2 {} }
  .menu-detail-section .product-detail-top .swiper-button .button-prev {
    @apply left-2 {} }
  .menu-detail-section .product-detail-top .swiper-button .button-next {
    @apply right-2 {} }

.menu-detail-section .product-title {
  @apply text-2xl font-bold leading-tight text-primary-500 xl:text-32 {} }

.menu-detail-section .product-price {
  @apply mt-5 text-2xl font-bold leading-tight text-green-500 xl:text-32 {} }

.menu-detail-section .product-size {
  @apply mt-7.5 flex items-center gap-5 text-lg font-medium leading-1.44 text-gray-66 {} }

.menu-detail-section .size-list {
  @apply flex items-center gap-7.5 {} }
  .menu-detail-section .size-list a {
    @apply body-2-text {}    @apply flex-center w-12.5 h-12.5 border border-primary-500 text-primary-500 bg-white rounded-1 hover:bg-secondary-500 hover:border-secondary-500 {} }
    .menu-detail-section .size-list a.active {
      @apply bg-secondary-500 border-secondary-500 {} }

.menu-detail-section .product-topping {
  @apply mt-12.5 text-lg font-medium leading-1.44 text-gray-66 {} }

.menu-detail-section .topping-list {
  @apply mt-7.5 max-h-[calc(315/1920*100rem)] custom-scroll {} }
  .menu-detail-section .topping-list li {
    @apply body-2-text {}    @apply flex items-center justify-between gap-2.5 text-gray-66 leading-tight py-2.75 border-b border-b-gray-100 {} }
  .menu-detail-section .topping-list .topping-price {
    @apply text-green-500 {} }

.menu-detail-section .product-button {
  @apply mt-7.5 xl:mt-15 {} }

.menu-detail-section .product-detail-bot > * {
  @apply mt-10 pt-10 border-t border-t-gray-100 {} }

.menu-detail-section .product-other-title {
  @apply heading-5-title {}  @apply font-bold leading-1.33 text-gray-33 mb-5 {} }

.menu-detail-section .full-content {
  @apply body-2-text {}  @apply font-medium leading-1.44 text-gray-66 {} }

.menu-detail-section .product-relative .product-other-title {
  @apply text-primary-500 {} }

.product-item {
  @apply max-w-[calc(300/1920*100rem)] mx-auto {} }
  .product-item .product-image {
    @apply relative z-1 h-65 pb-4 transition-all duration-200 ease-linear flex-center {} }
    .product-item .product-image a {
      @apply flex-center w-full h-full {} }
    .product-item .product-image::before {
      content: '';
      @apply w-full h-3.5 bg-[url('../img/product-shadow.png')] bg-center bg-no-repeat bg-contain absolute left-1/2 -translate-x-1/2 bottom-0 pointer-events-none {} }
    .product-item .product-image img {
      @apply max-w-full max-h-full h-full object-contain {} }
  .product-item .product-caption {
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
    @apply relative bg-product rounded-10 pl-8 pr-5 pb-7 pt-36 transition-all duration-200 ease-linear -mt-35 {} }
  .product-item .product-tag {
    @apply flex-center absolute top-0 right-0 px-2 py-1 text-center text-xl font-secondary font-medium leading-tight uppercase bg-gray-99 min-w-[calc(70/1920*100rem)] h-10 whitespace-nowrap {} }
    .product-item .product-tag.new {
      @apply bg-secondary-500 text-primary-700 {} }
    .product-item .product-tag.discount {
      @apply bg-red-500 text-white {} }
  .product-item .product-title {
    @apply text-lg font-bold font-secondary text-primary-500 leading-normal {} }
    .product-item .product-title a {
      @apply hover-underline {} }
  .product-item .product-topping {
    @apply mt-5 font-secondary text-base font-medium leading-normal text-gray-400 space-y-2.5 transition-all duration-200 ease-linear xl:text-sm {} }
    .product-item .product-topping li, .product-item .product-topping p {
      @apply pb-2.5 border-b border-b-gray-d9 {} }
  .product-item .product-button {
    @apply mt-5 uppercase transition-all duration-200 ease-linear {} }

@screen xl {
  .product-item .product-button {
    @apply -mt-11 opacity-0 pointer-events-none {} }
  .product-item:hover .product-image {
    @apply h-78 {} }
  .product-item:hover .product-caption {
    @apply pt-41 bg-green-700 {} }
  .product-item:hover .product-topping {
    @apply text-white {} }
  .product-item:hover .product-title {
    @apply text-green-300 {} }
  .product-item:hover .product-button {
    @apply opacity-100 mt-5 pointer-events-auto {} } }

.menu-list {
  @apply flex gap-2 overflow-x-auto overflow-y-hidden md:justify-center md:gap-7 {} }
  .menu-list a:hover {
    @apply text-white {} }
    .menu-list a:hover span {
      @apply text-current {} }
  .menu-list li {
    @apply w-max {} }
    .menu-list li.active a {
      @apply bg-primary-500 text-white {} }
      .menu-list li.active a span {
        @apply text-current {} }

footer {
  @apply overflow-hidden relative {} }
  footer .col-1-custom {
    @apply xl:w-[calc(245/1260*100%)] {} }
  footer .col-2-custom {
    @apply xl:w-[calc(565/1260*100%)] {} }
  footer .col-3-custom {
    @apply xl:w-[calc(450/1260*100%)] {} }
  footer .footer-title {
    @apply text-xl font-bold text-desc leading-normal mb-5 {} }
  footer .footer-menu {
    @apply space-y-3.75 xl:space-y-5 {} }
    footer .footer-menu a {
      @apply text-base font-normal leading-normal text-black-500 hover-underline hover:text-primary-500 {} }
  footer .footer-form {
    @apply relative max-w-[calc(410/1920*100rem)] z-0 {} }
    footer .footer-form .form-content {
      @apply text-xl font-bold text-black-950 mb-3.75 {} }
    footer .footer-form .wrap-form {
      box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);
      @apply bg-white rounded-7.5 p-5 md:p-7.5 lg:p-10 xl:py-12.5 {} }
      footer .footer-form .wrap-form .form-group {
        @apply mb-5 last:mb-0 {} }
      footer .footer-form .wrap-form .frm-btn-submit {
        background-image: url("../img/arrow-right-yellow.png");
        background-position: 85% center;
        @apply h-9 text-base font-medium w-25 rounded-1 pl-5 pr-10 min-w-0 bg-no-repeat {} }

@screen md {
  footer .footer-form::after {
    content: '';
    background-image: url("../img/footer-form-bg.png");
    @apply absolute top-1/2 -translate-y-1/2 left-[57%] -z-1 w-[calc(300/1920*100rem)] h-[calc(324/1920*100rem)] bg-contain bg-no-repeat {} } }
  footer .footer-maps {
    @apply grayscale {} }
    footer .footer-maps iframe {
      @apply w-full min-w-full h-[calc(370/1920*100rem)] {} }

@screen xl {
  footer .footer-maps {
    @apply absolute top-15 right-0 w-[calc(752/1920*100rem)] {} } }
  footer .footer-copyright {
    @apply text-sm font-medium text-gray-81 mt-5 xl:text-sm xl:text-right {} }
    footer .footer-copyright .ModuleContent {
      @apply text-sm font-medium text-gray-81 mt-5 xl:text-sm xl:text-right {} }
  footer .footer-terms {
    @apply mt-5 xl:mt-3.75 {} }
  footer .terms-list {
    @apply flex items-center flex-wrap gap-2.5 xl:justify-end xl:-mr-5 {} }
    footer .terms-list a {
      @apply border border-solid border-gray-100 rounded-full h-7.5 px-6 text-center flex-center text-gray-400 text-sm hover:bg-primary-500 hover:border-primary-500 hover:text-white md:text-sm xl:text-xs {} }
