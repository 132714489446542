.button
	@apply flex flex-wrap gap-5

.nav-button-list
	@apply flex flex-wrap gap-5 xl:gap-7.5
	li
		&.active
			a
				transform: translate(6px, -6px)
				@apply bg-secondary-500 text-primary-500 border-secondary-500
				&::after
					transform: translate(-6px, 6px)
					@apply opacity-100
				&.btn-hover-bg-lined
					@apply after:border-secondary-500

.link
	@apply flex-center space-x-2.5 font-bold text-base text-black-1000 xl:text-sm xl:hover:text-primary-700
	i,em
		@apply text-primary-700 transition-all duration-200 ease-linear xl:text-xl xl:hover:text-secondary-700
	&.link-primary
		@apply text-primary-700 xl:hover:text-secondary-700
	&.link-gray-33
		@apply text-gray-33 xl:hover:text-secondary-700
	&.link-white
		@apply text-white xl:hover:text-secondary-700
		i,em
			@apply text-white xl:hover:text-secondary-700

.btn
	@apply relative flex-center h-11 px-5.5 gap-2 rounded-0.75 border-2 border-solid border-current text-lg font-medium leading-normal min-w-[calc(180/1920*100rem)] max-w-full z-1

.btn-small
	@apply h-9 min-w-0 border

.btn-big
	@apply h-15 px-7.5

.btn-solid
	@apply text-white bg-primary-500 border-primary-500
	&.btn-primary
		@apply bg-primary-500 text-secondary-500 border-primary-500 hover:text-primary-500 hover:bg-white
		&.btn-hover-bg-lined
			@apply after:border-primary-500 hover:bg-secondary-500 hover:text-primary-500 hover:border-secondary-500 hover:after:border-secondary-500
	&.btn-secondary
		@apply bg-secondary-500 text-primary-500 border-secondary-500 hover:text-secondary-500 hover:bg-white
		&.btn-hover-bg-lined
			@apply after:border-secondary-500 hover:bg-secondary-500 hover:text-primary-500 hover:after:border-secondary-500
	&.btn-white
		@apply bg-white text-primary-500 border-white hover:text-white hover:bg-primary-500 hover:border-primary-500

.btn-lined
	@apply bg-white text-primary-500 border-primary-500
	&.btn-primary
		@apply text-primary-500 border-primary-500 hover:bg-primary-500
		span
			@apply text-primary-500
		&:hover
			span, i, em
				@apply text-white

.btn-hover-bg-solid
	&:hover
		transform: translate(6px, -6px)
		box-shadow: -6px 6px 0 currentColor

.btn-hover-bg-lined
	&::after
		content: ''
		@apply bg-transparent border border-solid border-current rounded-0.75 absolute top-0 left-0 w-full h-full opacity-0 pointer-events-none -z-1 transition-all duration-200 ease-linear
	&:hover
		transform: translate(6px, -6px)
		&::after
			transform: translate(-6px, 6px)
			@apply opacity-100
