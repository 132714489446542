.home-1
	@apply pt-15 lg:pb-50 xl:pb-50
	.block-content
		@apply mt-10 xl:mt-[calc(115/1920*100rem)]
	.global-desc
		@apply max-w-md lg:max-w-[calc(475/1920*100rem)]
	.sunday-info
		box-shadow: 0px -4px 4px rgba(224, 224, 224, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.1)
		@apply flex flex-wrap gap-5 bg-secondary-500 rounded-2 py-5 px-12.5 text-center mt-7.5 max-w-md md:text-left md:py-3 md:px-5 xl:mt-10 xl:pl-6 xl:pr-8 xl:gap-7.5 lg:max-w-[calc(560/1920*100rem)]
		> *
			@apply w-full md:w-auto
	.number
		@apply flex items-start text-4xl text-primary-500 font-medium uppercase leading-tight justify-center md:justify-start md:text-3xl xl:text-4xl
		span:not(.count-up)
			@apply text-lg md:text-xl xl:text-2xl
	li
		@apply relative text-base leading-tight text-primary-500 tracking-tight font-normal pr-5 xl:pr-7.5 last:pr-0 last:before:hidden
		strong
			@apply text-lg font-medium block whitespace-nowrap
		@screen md
			&::before
				content: ''
				@apply absolute right-0 top-1/2 -translate-y-1/2 w-px h-5/6 bg-gradient-to-b from-white/0 via-primary-500 to-white/0

	@media(max-width: 1023.89px)
		background: linear-gradient(179.92deg, rgba(217, 255, 176, 0) 0.07%, rgba(217, 255, 176, 0.5) 101.45%) !important

.home-2

.home-3
	@apply relative z-2 lg:px-15 xl:pt-30 xl:pb-0
	&::before
		content: ''
		background-image: radial-gradient(87.32% 87.32% at 60.6% 53.77%, #086812 0%, #01481D 100%)
		@apply absolute top-0 left-1/2 -translate-x-1/2 w-[calc(100%-(10/1920*100rem))] h-full -z-1 rounded-10 lg:hidden
	.bg-home-3
		@apply lg:bg-[url('../img/home-bg-1.png')] lg:bg-bottom lg:bg-no-repeat lg:bg-cover lg:rounded-[55px]
	.image
		@apply text-right
		img
			@apply lg:w-full lg:h-full lg:object-contain
		@screen lg
			@apply absolute right-1 bottom-7 max-w-[calc(490/1920*100rem)] w-full
	.global-desc
		@apply leading-tight
	&.franchising-1
		.bg-home-3
			@apply lg:min-h-[calc(477/1920*100rem)] lg:flex-center
			@screen lg
				background: radial-gradient(87.32% 87.32% at 60.6% 53.77%, #086812 0%, #01481D 100%)
		.image
			@screen lg
				@apply max-w-none -bottom-15 -right-15

.home-4
	@apply pb-0
