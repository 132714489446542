footer
	@apply overflow-hidden relative
	.col-1-custom
		@apply xl:w-[calc(245/1260*100%)]
	.col-2-custom
		@apply xl:w-[calc(565/1260*100%)]
	.col-3-custom
		@apply xl:w-[calc(450/1260*100%)]
	.footer-title
		@apply text-xl font-bold text-desc leading-normal mb-5
	.footer-menu
		@apply space-y-3.75 xl:space-y-5
		a
			@apply text-base font-normal leading-normal text-black-500 hover-underline hover:text-primary-500
	.footer-form
		@apply relative max-w-[calc(410/1920*100rem)] z-0
		.form-content
			@apply text-xl font-bold text-black-950 mb-3.75
		.wrap-form
			box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1)
			@apply bg-white rounded-7.5 p-5 md:p-7.5 lg:p-10 xl:py-12.5
			.form-group
				@apply mb-5 last:mb-0
			.frm-btn-submit
				background-image: url('../img/arrow-right-yellow.png')
				background-position: 85% center
				@apply h-9 text-base font-medium w-25 rounded-1 pl-5 pr-10 min-w-0 bg-no-repeat
		@screen md
			&::after
				content: ''
				background-image: url('../img/footer-form-bg.png')
				@apply absolute top-1/2 -translate-y-1/2 left-[57%] -z-1 w-[calc(300/1920*100rem)] h-[calc(324/1920*100rem)] bg-contain bg-no-repeat
	.footer-maps
		@apply grayscale
		iframe
			@apply w-full min-w-full h-[calc(370/1920*100rem)]
		@screen xl
			@apply absolute top-15 right-0 w-[calc(752/1920*100rem)]
	.footer-copyright
		@apply text-sm font-medium text-gray-81 mt-5 xl:text-sm xl:text-right
		.ModuleContent
			@apply text-sm font-medium text-gray-81 mt-5 xl:text-sm xl:text-right
	.footer-terms
		@apply mt-5 xl:mt-3.75
	.terms-list
		@apply flex items-center flex-wrap gap-2.5 xl:justify-end xl:-mr-5
		a
			@apply border border-solid border-gray-100 rounded-full h-7.5 px-6 text-center flex-center text-gray-400 text-sm hover:bg-primary-500 hover:border-primary-500 hover:text-white md:text-sm xl:text-xs
