.global-breadcrumb
	@apply bg-gray-50/50
	.breadcrumb
		@apply flex flex-wrap items-center py-1.75
		a
			@apply block text-lg leading-normal font-medium text-gray-300 hover:text-primary-500
		li
			@apply px-2 h-full flex-center last:after:hidden
			&::after
				content: ''
				@apply flex-center w-px h-3.5 bg-gray-300 ml-3.75
			&:first-child
				a
					font-size: 0
					span
						@apply hidden
					&::before
						@apply font-awesome content-['\f015'] text-base flex-center w-6 h-6

.banner-child
	& + .global-breadcrumb
		@apply pt-0
