.dealer-gallery-item
	@apply rounded-2 overflow-hidden
	img
		@apply w-full h-full object-contain
	iframe
		@apply w-0 min-w-full h-full min-h-[calc(375/1920*100rem)]

.dealer-gallery-list
	@apply gap-2.5 flex flex-col-reverse md:flex-col

.dealer-related-item
	@apply flex
	.image
		@apply w-1/2 md:w-[calc(212/1920*100rem)]
	.img-scale
		@apply pt-[calc(120/212*100%)] rounded-2
	.caption
		@apply flex-1 pl-4
	.title
		@apply body-2-text
		@apply font-bold text-primary-500 leading-1.44 mb-3
	.location
		@apply body-2-text
		@apply font-medium text-gray-66
		li
			@apply flex items-center gap-3
		i, em
			@apply w-4 text-black-1000
		p
			@apply flex-1

.dealer-related-list
	@apply space-y-7.5

.dealer-detail-section
	.global-title
		@apply normal-case
	.shared-wrap
		@apply body-2-text
		@apply font-medium leading-1.44 text-gray-33
		@apply flex items-center gap-5.5 mt-5 pl-2
	.dealer-info
		@apply body-2-text
		@apply mt-5.5 text-gray-66 font-medium leading-1.44 space-y-3
		ul
			@apply space-y-3
		li
			@apply flex items-center gap-3
			i, em
				@apply w-4 text-black-1000
			p
				@apply flex-1
	.dealer-related
		@apply mt-5 pt-5 border-t border-t-gray-100
	.dealer-related-title
		@apply body-2-text
		@apply font-bold text-primary-500 leading-1.44 mb-7.5
