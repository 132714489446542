.recruitment-filter
	@apply bg-gray-50/50 rounded-2 p-5 lg:flex lg:items-center lg:gap-5 xl:gap-0 xl:pl-10 xl:pr-2.5 xl:py-3
	.form-group
		@apply flex flex-col gap-1 space-y-0 lg:gap-5 lg:mb-0 lg:flex-row lg:items-center
		label
			@apply text-lg font-medium text-gray-33 leading-1.44 lg:w-auto
		select
			@apply h-[36px] bg-white border-white rounded-1 px-5.5 text-lg lg:w-[calc(380/1920*100rem)] lg:max-w-full
		.btn-submit
			@apply rounded-2 h-[32px] text-base flex-center lg:px-2.5 hover:text-white
			&:hover
				span
					@apply text-white
		& + .form-group:not(.form-submit)
			@apply xl:ml-20
		& + .form-group.form-submit
			@apply xl:ml-5.5

.table-responsive
	table
		@apply w-full rounded-2 overflow-hidden
	th
		@apply border border-gray-50 bg-primary-500 text-lg font-medium text-white text-center px-2.5 py-2.75
	td
		@apply border border-gray-50 text-lg font-medium text-gray-33 text-center px-2.5 py-2.75
	a
		@apply hover:text-green-500
	@media(max-width: 767.89px)
		@apply rounded-none
		thead
			@apply hidden
		table, tbody, tr, td
			@apply block w-full
		tbody
			@apply space-y-5
		tr
			@apply border border-gray-50 rounded-2 p-5 space-y-1
		td
			padding: 0 !important
			@apply border-none text-left font-normal
			&::before
				content: attr(data-title)
				@apply font-medium mr-1
		a
			@apply font-medium

.recruitment-list
	.recruitment-wrap
		@apply mt-10 xl:mt-12.5
	.table-responsive
		th, td
			&:nth-child(2), &:nth-child(4)
				@apply text-left px-10

.apply-frm
	.apply-title
		@apply text-2xl mb-5 font-bold text-gray-33 md:text-3xl xl:text-4xl
	.form-group
		@apply space-y-0 mb-6
		label
			@apply hidden
		input
			@apply rounded-1 border-[#E0E0E0] xl:h-10
		textarea
			@apply rounded-1 border-[#E0E0E0] xl:h-25
		.btn-submit
			background-image: url('../img/arrow-right-yellow.png')
			background-position: 85% center
			@apply h-9 text-base font-medium w-25 rounded-1 pl-5 pr-10 min-w-0 bg-no-repeat
	.attachfile
		@apply sm:flex sm:items-center sm:gap-5
		> *
			@apply sm:flex-1
		label
			@apply block text-base text-gray-66 font-bold uppercase
		input[type='file']
			@apply block
		.RadUpload
			@apply w-full font-primary

	.wrap-form
		.row
			@apply sm:-mx-4
			> *
				@apply sm:px-4
