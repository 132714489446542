.modal
	@apply max-w-6xl w-full p-0 rounded-5
	.modal-content
		@apply p-5 md:p-7.5 xl:p-10

.modal-recrutment-apply
	@apply max-w-[calc(928/1920*100rem)]
	.modal-content
		@apply xl:px-8 xl:py-12.5
	iframe
		@apply w-full h-[calc(400/1920*100rem)]
