.franchising-conditions-item
	.icon
		@apply w-17.5 h-17.5 mx-auto
	.title
		@apply body-2-text
		@apply mt-7.5 font-medium text-gray-33

.franchising-3
	.global-desc
		@apply body-2-text
		@apply font-medium leading-1.44 text-gray-33
		ul
			@apply pl-5 list-disc space-y-1.5
		li
			@apply marker:text-secondary-500 marker:text-xl
	.image
		@apply max-w-[calc(600/1920*100rem)] mx-auto
	.img-scale
		@apply pt-[calc(357/600*100%)] rounded-2

.franchising-4
	.image
		@apply px-3.75
	.franchising-contact
		@apply mt-10 text-center text-base lg:mt-15 lg:text-xl lg:text-2xl
		h3
			@apply body-1-text
			@apply font-medium text-primary-500 uppercase mb-5 md:text-3xl xl:text-4xl
		a
			@apply font-bold text-primary-500 hover-underline
