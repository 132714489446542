@import 'src/components/_core/_mixins'

.edit-link i:before
	content: '✏️'

.social-list
	@apply flex items-center gap-3
	a
		@apply flex-center w-12.5 h-12.5 bg-primary-500 text-white text-2xl rounded-2.5 hover:bg-primary-700

.nav-fixed
	@apply fixed z-50 bottom-24 right-3 xl:bottom-14 xl:right-12
	ul
		@apply flex flex-col gap-2.5
	li
		@apply w-15 h-15 rounded-full bg-white overflow-hidden shadow
		a
			@apply flex-center w-full h-full text-2xl text-primary-500
		&.back-to-top
			@apply opacity-0 pointer-events-none
			&.active
				@apply opacity-100 pointer-events-auto
