.product-item
	@apply max-w-[calc(300/1920*100rem)] mx-auto
	.product-image
		@apply relative z-1 h-65 pb-4 transition-all duration-200 ease-linear flex-center
		a
			@apply flex-center w-full h-full
		&::before
			content: ''
			@apply w-full h-3.5 bg-[url('../img/product-shadow.png')] bg-center bg-no-repeat bg-contain absolute left-1/2 -translate-x-1/2 bottom-0 pointer-events-none
		img
			@apply max-w-full max-h-full h-full object-contain
	.product-caption
		box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1)
		@apply relative bg-product rounded-10 pl-8 pr-5 pb-7 pt-36 transition-all duration-200 ease-linear -mt-35
	.product-tag
		@apply flex-center absolute top-0 right-0 px-2 py-1 text-center text-xl font-secondary font-medium leading-tight uppercase bg-gray-99 min-w-[calc(70/1920*100rem)] h-10 whitespace-nowrap
		&.new
			@apply bg-secondary-500 text-primary-700
		&.discount
			@apply bg-red-500 text-white
	.product-title
		@apply text-lg font-bold font-secondary text-primary-500 leading-normal
		a
			@apply hover-underline
	.product-topping
		@apply mt-5 font-secondary text-base font-medium leading-normal text-gray-400 space-y-2.5 transition-all duration-200 ease-linear xl:text-sm
		li, p
			@apply pb-2.5 border-b border-b-gray-d9
	.product-button
		@apply mt-5 uppercase transition-all duration-200 ease-linear
	@screen xl
		.product-button
			@apply -mt-11 opacity-0 pointer-events-none
		&:hover
			.product-image
				@apply h-78
			.product-caption
				@apply pt-41 bg-green-700
			.product-topping
				@apply text-white
			.product-title
				@apply text-green-300
			.product-button
				@apply opacity-100 mt-5 pointer-events-auto

.menu-list
	@apply flex gap-2 overflow-x-auto overflow-y-hidden md:justify-center md:gap-7
	a
		&:hover
			@apply text-white
			span
				@apply text-current
	li
		@apply w-max
		&.active
			a
				@apply bg-primary-500 text-white
				span
					@apply text-current

.menu-list-section
